<template>
    <div class="wrapper-content">
        <div class="wrapper-content__header">
        </div>
        <div class="wrapper-content__body">
            <!-- Main variables *content* -->
            <div id="block_69">
                <div class="sign-in">
                    <div class="bg"></div>
                    <div class="divider-top"></div>
                    <div class="divider-bottom"></div>
                    <div class="container">
                        <div class="row sign-up-center-alignment">
                            <div class="col-lg-8">
                                <div class="component_card">
                                    <div class="card">
                                        <!-- <form action=""> -->

                                        <div>
                                            <div class="component_form_group">
                                                <div class="">
                                                    <div class="form-group">
                                                        <label for="login" class="control-label">{{
                                                            $t("userManager.username")
                                                        }}</label>
                                                        <input type="text" class="form-control" id="login" value=""
                                                            v-model="formInput.username" name="RegistrationForm[login]">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="component_form_group">
                                                <div class="">
                                                    <div class="form-group">
                                                        <label for="email" class="control-label">{{
                                                            $t("userManager.email")
                                                        }}</label>
                                                        <input type="email" class="form-control" id="email" value=""
                                                            v-model="formInput.email" name="RegistrationForm[email]">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="component_form_group">
                                                <div class="">
                                                    <div class="form-group">
                                                        <label for="password" class="control-label">{{
                                                            $t("userManager.Password")
                                                        }}</label>
                                                        <input type="password" class="form-control" id="password" value=""
                                                            v-model="formInput.password" name="RegistrationForm[password]">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="component_form_group">
                                                <div class="">
                                                    <div class="form-group">
                                                        <label for="password_again" class="control-label">{{
                                                            $t("userManager.isPassword")
                                                        }}</label>
                                                        <input type="password" class="form-control" id="password_again"
                                                            value="" v-model="formInput.passwor"
                                                            name="RegistrationForm[password_again]">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="h-captcha form-group"
                                                data-sitekey="df2e643c-0dbe-4a52-b9d0-827f6649ce3d"></div>
                                        </div>

                                        <input type="hidden" name="_csrf"
                                            value="WBUvDFEc8eziawwVxBcyvAW60qc2_S1nLYtey1nlU0QaXGc4PHqumokJQXKJJnXZbde9wECTWVVfuzuTMasFdA==">
                                        <div class="component_button_submit">
                                            <div class="form-group">
                                                <div class="">
                                                    <button type="submit" class="btn btn-block btn-big-primary"
                                                        @click="signup">{{ $t("userManager.Signup") }}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center">{{ $t("userManager.Donothaveanaccountyou") }}<router-link
                                                to="/" class="sign-up-center-signin-link">{{
                                                    $t("userManager.Signin")
                                                }}</router-link></div>
                                        <!-- </form> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="wrapper-content__footer">
            <div id="block_70">
                <div class="footer ">
                    <div class="component_footer_single_line">
                        <div class="component-footer">
                            <div class="component-footer__public">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="component-footer__public-copyright">
                                                <p class="text-center"><span style="text-align: CENTER">{{
                                                    $t("userManager.banquan")
                                                }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { register } from "@/utils/http.js";

export default {
    data() {
        return {
            formInput: {
                username: '',
                password: '',
                passwor: '',
                email: '',
            },
        }
    },
    methods: {
        async signup() {
            // console.log(this.formInput, 'signup');
            if (this.formInput.username != '' && this.formInput.password != '' && this.formInput.passwor != '' && this.formInput.email != '') {
                if (this.formInput.password == this.formInput.passwor) {
                    delete this.formInput.passwor
                    // console.log(this.formInput, 'this.formInputthis.formInputthis.formInput');
                    let res = await register(this.formInput)

                    // let res = await this.$http.post('/register', this.formInput)
                    console.log(res);
                    if(res.code==1){
                         this.$message({
                        message:res.message,
                        type: 'success'
                    });
                    this.$router.push('/')
                    }else if(res.code==-1){
                        this.$message({
                        message: res.message,
                        type: 'error'
                    })
                    }
                   
                } else {
                    this.$message.error('密码不一致，请重新输入');
                }
            }else{
                this.$message.error('注册信息不能为空');
            }

        }
    }
}
</script>