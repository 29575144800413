<template>
    <div class="wrapper-content">
    <div class="wrapper-content__header">
          </div>
    <div class="wrapper-content__body">
      <!-- Main variables *content* -->
      <div id="block_49">
	<div class="orders-history ">
		<div class="bg"></div>
		<div class="divider-top"></div>
		<div class="divider-bottom"></div>
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<div class="orders-history__margin-tab">
						<div class="component_status_tabs">
							<div class="">
								<ul class="nav nav-pills tab">
                                    <li class="nav-item" v-for="(item) in $t('userManager.ordertab')" :key="item.id" >
										<router-link class="nav-link" :to="item.link" exact-active-class="active">{{ item.text }}</router-link>
									</li>
									<!-- <li class="nav-item">
										<router-link class="nav-link " to="/orders">All</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link " to="/orders/pending">Pending</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link " to="/orders/inprogress">In progress</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link active" to="/orders/completed">Completed</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link " to="/orders/partial">Partial</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link " to="/orders/processing">Processing</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link " to="/orders/canceled">Canceled</router-link>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="orders-history__margin-table">
						<div class="table-bg component_table ">
							<div class="table-wr ">
								<table class="table">
									<thead>
										<tr>
											<th>
												<div class="orders-history__margin-search orders-history__header-search">
													<div class="component_form_group component_button_search">
														<div class="">
															<form action="/orders" method="get" id="history-search">
																<div class="input-group">
																	<input type="text" name="search" class="form-control" v-model="orderinput" value="" placeholder="Search">
																	<div class="input-group-append">
																		<button class="btn btn-big-secondary" type="submit" @click="searchorder">
																			<span class="fas fa-search"></span>
																		</button>
																	</div>
																</div>
															</form>
														</div>
													</div>
												</div>
											</th>
										</tr>
									</thead>
								</table>
								<div class="table-responsive table-responsive__search ">
									<table class="table">
										<thead>
											<tr>
												<th>ID</th>
												<th>{{
                                                    $t("userManager.data")
                                                }}</th>
												<th>{{
                                                    $t("userManager.link")
                                                }}</th>
												<th>{{
                                                    $t("userManager.Charge")
                                                }}</th>
												<th class="nowrap">{{
                                                    $t("userManager.Startcount")
                                                }}</th>
												<th>{{
                                                    $t("userManager.Quantity")
                                                }}</th>
												<th>{{
                                                    $t("userManager.Service")
                                                }}</th>
												<th>{{
                                                    $t("userManager.Status")
                                                }}</th>
												<th>{{
                                                    $t("userManager.Remains")
                                                }}</th>
																							</tr>
										</thead>
										<tbody>
																							<tr>
													<td colspan="9">&nbsp;</td>
												</tr>
																					</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-5">
					<nav class="component_pagination">
						<div class="">
														</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>

    </div>
    <div class="wrapper-content__footer">
           </div>
  </div>
</template>
<script>
export default {
    data () {
        return {
            // activenavid:1,
            orderinput:'',
            ordertab:[
                {id:1,text:'All',link:'/orders'},
                {id:2,text:'Pending',link:'/orders/pending'},
                {id:3,text:'In progress',link:'/orders/inprogress'},
                {id:4,text:'Completed',link:'/orders/completed'},
                {id:5,text:'Partial',link:'/orders/partial'},
                {id:6,text:'Processing',link:'/orders/processing'},
                {id:7,text:'Canceled',link:'/orders/canceled'},
                ]
        }
    },
    methods:{
        searchorder(){
            console.log(this.orderinput,'searchorder');
        }
    }
}
</script>