<template>
    <div class="wrapper-content">
        <div class="wrapper-content__header">
        </div>
        <div class="wrapper-content__body">
            <!-- Main variables *content* -->
            <div id="block_74">
                <div class="reset-password-form">
                    <div class="bg"></div>
                    <div class="divider-top"></div>
                    <div class="divider-bottom"></div>
                    <div class="container">
                        <div class="row reset-password-form__alignment">
                            <div class="col-lg-4">
                                <div class="component_card">
                                    <div class="card">
                                        <!-- <form method="post" action=""> -->
                                            <div class="component_form_group">
                                                <div class="">
                                                    <div class="form-group">
                                                        <label for="email" class="control-label">Email</label>
                                                        <input type="email" class="form-control" id="email"  v-model="formInput.email"
                                                            name="ResetPasswordForm[email]">
                                                    </div>
                                                    <div class="h-captcha form-group"
                                                        data-sitekey="df2e643c-0dbe-4a52-b9d0-827f6649ce3d"></div>
                                                </div>
                                                <input type="hidden" name="_csrf"
                                                    value="NA3cg4In-gd2DG1Qp9rB71Nwn9jj7EQc4DfvtVzoiP5fNO_Q702PYj9AFTWek7eGJhnylICqEHOFdaj_PtnJyw==">
                                                <div class="component_button_submit">
                                                    <div class="">
                                                        <button type="submit" @click="send"
                                                            class="btn btn-block btn-big-primary">Send</button>
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- </form> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper-content__footer">
            <div id="block_70">
                <div class="footer ">
                    <div class="component_footer_single_line">
                        <div class="component-footer">
                            <div class="component-footer__public">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="component-footer__public-copyright">
                                                <p class="text-center"><span style="text-align: CENTER">© Copyright
                                                        SMM918.COM. All Rights Reserved.</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div></template>
<script>
export default {
    data () {
        return {
            formInput: {
                email: '',
            },
        }
    },
    methods:{
        send(){
            console.log(this.formInput.email,'');
        }
    }
}
</script>