<template>
  <!-- <div id="app"> -->

  <div class="wrapper  wrapper-navbar " v-if="!istoken">
    <!-- <el-dropdown class="lang-container hover-pointer" trigger="click">
    <span>{{ language }} <i class="el-icon-caret-bottom el-icon--right" />
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="changeLangauge('zh')">
        <span style="display:block;">简体中文</span>
      </el-dropdown-item>
      <el-dropdown-item @click.native="changeLangauge('en')">
        <span style="display:block;">English</span>
      </el-dropdown-item>
      <el-dropdown-item @click.native="changeLangauge('ja')">
        <span style="display:block;">日本语</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown> -->

    <!-- <h3 class="title">{{ $t('userManager.username') }}</h3> -->
    <!-- <nav> -->
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/api">About</router-link> -->
    <div id="block_54">
      <div class="block-wrapper">
        <div class="component_navbar ">
          <div class="component-navbar__wrapper">
            <div class="sidebar-block__top component-navbar component-navbar__navbar-public editor__component-wrapper">
              <div>
                <nav class="navbar navbar-expand-lg navbar-light container-lg ">
                  <div class="navbar-public__header">
                    <div class="sidebar-block__top-brand">
                      <div class="component-navbar-brand component-navbar-public-brand">
                        <p><span style="font-size: 26px"><span style="text-transform: uppercase"><span
                                style="letter-spacing: 1.7px"><strong
                                  style="font-weight: bold">smm918</strong></span></span></span></p>
                      </div>
                    </div>
                    <button class="navbar-toggler" @click="ydnavgp()" type="button" data-toggle="collapse"
                      data-target="#navbar-collapse-54" aria-controls="navbar-collapse-54" aria-expanded="false"
                      aria-label="Toggle navigation">
                      <span class="navbar-burger">
                        <span class="navbar-burger-line"></span>
                      </span>
                    </button>
                  </div>
                  <div class="collapse navbar-collapse" id="navbar-collapse-54">
                    <div class="component-navbar-collapse-divider"></div>
                    <div class="navbar-languages component_navbar_dropdown" @click="yiru">
                      <div :class="[yichuruflag ? 'show navbar-dropdown' : 'navbar-dropdown']">
                        <div class="navbar-dropdown__name navbar-dropdown__toggle" data-toggle="dropdown"
                          data-hover="dropdown" :aria-expanded="yichuruflag">{{ yuyanactive.text }}</div>
                        <!-- 一个变量 -->
                        <ul
                          :class="[yichuruflag ? 'show navbar-dropdown__container dropdown-menu' : 'navbar-dropdown__container dropdown-menu']">
                          <li class="navbar-dropdown__item" v-for="(item) in navbarlink" :key="item.id"
                            @click="yuyango(item.id, item.link)">
                            <div class="navbar-dropdown__link">{{ item.text }}</div>
                          </li>

                          <!-- 改成数据数组bind -->

                          <!-- <li class="navbar-dropdown__item">
                            <a href="/ru" class="navbar-dropdown__link">Russian</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/tr" class="navbar-dropdown__link">Turkish</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/bp" class="navbar-dropdown__link">Portuguese (Brazil)</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/ko" class="navbar-dropdown__link">Korean</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/ar" class="navbar-dropdown__link">Arabic</a>
                          </li> -->
                        </ul>
                      </div>
                    </div>
                    <div class="d-flex component-navbar-collapse">
                      <ul class="navbar-nav">
                        <li class="nav-item component-navbar-nav-item component_navbar component-navbar-public-nav-item"
                          @click="isgbnav()">
                          <router-link class="component-navbar-nav-link component-navbar-nav-link__navbar-public "
                            exact-active-class="active" to="/">{{ $t('userManager.Signin') }}</router-link>
                        </li>
                        <!-- <li
                            class="nav-item component-navbar-nav-item component_navbar component-navbar-public-nav-item">
                            <a class="component-navbar-nav-link component-navbar-nav-link__navbar-public "
                              href="/services"> Services</a>
                          </li> -->
                        <li class="nav-item component-navbar-nav-item component_navbar component-navbar-public-nav-item"
                          @click="isgbnav()">
                          <router-link class="component-navbar-nav-link component-navbar-nav-link__navbar-public "
                            exact-active-class="active" to="/api">
                            {{ $t('userManager.api') }}</router-link>
                        </li>
                        <li class="nav-item component-navbar-nav-item component_navbar component-navbar-public-nav-item"
                          @click="isgbnav()">
                          <router-link class="component-navbar-nav-link component-navbar-nav-link__navbar-public "
                            exact-active-class="active" to="/signup">
                            {{ $t('userManager.Signup') }}</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="component_navbar"></div>
    </div>
    <!-- </nav> -->
    <router-view />
  </div>
  <!-- </div> -->

  <div class="wrapper  wrapper-sidebar-navbar " v-else>
    <div id="block_61" class="component_private_navbar">
      <span class="component_private_navbar ">
        <div class="component-navbar-private__wrapper component_private_sidebar">
          <div class="sidebar-block__top component-navbar component-navbar__navbar-private editor__component-wrapper">
            <div>
              <nav class="navbar navbar-expand-lg navbar-light">
                <div class="navbar-private__header">
                  <div class="sidebar-block__top-brand">
                    <div class="component-navbar-brand component-navbar-public-brand">
                      <router-link target="_self" to="/"><span style="text-transform: uppercase"><span
                            style="font-size: 24px"><span style="letter-spacing: 1.0px"><span
                                style="line-height: 48px"><strong
                                  style="font-weight: bold">smm918.com</strong></span></span></span></span></router-link>
                    </div>
                  </div>
                  <button class="navbar-toggler" @click="ydnavgp()" type="button" data-toggle="collapse"
                    data-target="#navbar-collapse-61" aria-controls="navbar-collapse-61" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-burger">
                      <span class="navbar-burger-line"></span>
                    </span>
                  </button>
                </div>
                <div class="collapse navbar-collapse" id="navbar-collapse-61">
                  <div class="component-navbar-collapse-divider"></div>
                  <div class="d-flex component-navbar-collapse">
                    <ul class="navbar-nav navbar-nav-sidebar-menu">
                      <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item" @click="isgbnav"
                        v-for="(item) in $t('userManager.playnav')" :key="item.id">
                        <router-link class="component-navbar-nav-link  component-navbar-nav-link__navbar-private "
                          exact-active-class="component-navbar-nav-link-active__navbar-private" :to="item.link">
                          <span class="component-navbar-nav-link-icon">
                            <span :class="'fas ' + item.spanclass"></span>
                          </span>
                          {{ item.text }}
                        </router-link>
                      </li>
                      <!-- <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                      <a class="component-navbar-nav-link  component-navbar-nav-link__navbar-private " href="/services">
                        <span class="component-navbar-nav-link-icon">
                          <span class="fad fa-align-justify"></span>
                        </span>
                        Services
                      </a>
                    </li> -->
                      <!-- <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                        <router-link class="component-navbar-nav-link  component-navbar-nav-link__navbar-private "
                          to="/orders">
                          <span class="component-navbar-nav-link-icon">
                            <span class="fas fa-ballot-check"></span>
                          </span>
                          Orders
                        </router-link>
                      </li>
                      <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                        <router-link class="component-navbar-nav-link  component-navbar-nav-link__navbar-private "
                          to="/addfunds">
                          <span class="component-navbar-nav-link-icon">
                            <span class="fas fa-badge-dollar"></span>
                          </span>
                          Add funds
                        </router-link>
                      </li>
                      <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                        <router-link class="component-navbar-nav-link  component-navbar-nav-link__navbar-private "
                          to="/api">
                          <span class="component-navbar-nav-link-icon">
                            <span class="fas fa-code"></span>
                          </span>
                          API
                        </router-link>
                      </li> -->
                      <!-- <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                      <a class="component-navbar-nav-link  component-navbar-nav-link__navbar-private " href="/tickets">
                        <span class="component-navbar-nav-link-icon">
                          <span class="fas fa-ticket-alt"></span>
                        </span>
                        Tickets
                      </a>
                    </li>
                    <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                      <a class="component-navbar-nav-link  component-navbar-nav-link__navbar-private " href="/massorder">
                        <span class="component-navbar-nav-link-icon">
                          <span class="far fa-shopping-cart"></span>
                        </span>
                        Mass order
                      </a>
                    </li> -->
                    </ul>
                    <div class="navbar-languages component_navbar_dropdown" @click="yiru">
                      <div :class="[yichuruflag ? 'show navbar-dropdown' : 'navbar-dropdown']">
                        <div class="navbar-dropdown__name navbar-dropdown__toggle" data-toggle="dropdown"
                          data-hover="dropdown" :aria-expanded="yichuruflag">{{ yuyanactive.text }}</div>
                        <!-- 一个变量 -->
                        <ul
                          :class="[yichuruflag ? 'show navbar-dropdown__container dropdown-menu' : 'navbar-dropdown__container dropdown-menu']">
                          <li class="navbar-dropdown__item" v-for="(item) in navbarlink" :key="item.id"
                            @click="yuyango(item.id, item.link)">
                            <div class="navbar-dropdown__link">{{ item.text }}</div>
                          </li>

                          <!-- 改成数据数组bind -->

                          <!-- <li class="navbar-dropdown__item">
                            <a href="/ru" class="navbar-dropdown__link">Russian</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/tr" class="navbar-dropdown__link">Turkish</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/bp" class="navbar-dropdown__link">Portuguese (Brazil)</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/ko" class="navbar-dropdown__link">Korean</a>
                          </li>
                          <li class="navbar-dropdown__item">
                            <a href="/ar" class="navbar-dropdown__link">Arabic</a>
                          </li> -->
                        </ul>
                      </div>
                    </div>



                    <ul class="navbar-nav navbar-nav-currencies">
                      <li
                        class="nav-item d-flex align-items-center component-navbar-nav-item component-navbar-private-nav-item component-navbar-balance-wrap__navbar-private">
                        <a class="component-navbar-balance-item__navbar-private"><span
                            class="js-user-balance_formatted">$0.00</span></a>
                      </li>
                    </ul>
                    <ul class="navbar-nav">
                      <!-- <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item">
                        <a class="component-navbar-nav-link  component-navbar-nav-link__navbar-private"
                          href="/account">Account</a>
                      </li> -->
                      <li class="nav-item component-navbar-nav-item component-navbar-private-nav-item" @click="logout">
                        <router-link class="component-navbar-nav-link  component-navbar-nav-link__navbar-private"
                          to="/">{{ $t('userManager.Logout') }}</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </span>
      <span class="component_private_sidebar ">
        <div class="component-sidebar_wrapper">
          <div
            class="sidebar-block__left component-sidebar component-sidebar-with-navbar component_private_navbar editor__component-wrapper">
            <div class="component-sidebar__menu">
              <div class="component-sidebar__menu-logo">
                <div class="component-navbar-brand component-sidebar__menu-brand">
                  <router-link target="_self" to="/"><span style="text-transform: uppercase"><span
                        style="font-size: 24px"><span style="letter-spacing: 1.0px"><span
                            style="line-height: 48px"><strong
                              style="font-weight: bold">smm918.com</strong></span></span></span></span></router-link>
                </div>
              </div>
              <ul class="sidebar-block__left-menu editor__component-wrapper">
                <li
                  :class="[menuitemactive == item.id ? 'component-sidebar__menu-item-active' : 'component-sidebar__menu-item ']"
                  v-for="item in  $t('userManager.playnav')" :key="item.id" @click="menuitemactive = item.id">
                  <router-link class="component-sidebar__menu-item-link" :to="item.link">
                    <span class="sidebar-block__menu-item-icon">
                      <span :class="'fas ' + item.spanclass"></span>
                    </span>
                    {{ item.text }}
                  </router-link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </span>
    </div>
    <router-view />
  </div>
</template>
<script>
import $ from "jquery"
import {
  mapActions
} from "vuex";
export default {
  data() {
    return {
      istoken: false,
      menuitemactive: 1,
      yuyanactive: { id: 7, text: 'Simplified Chinese', link: 'zh' },
      yichuruflag: false,
      navbarlink: [
        { id: 1, text: 'English', link: 'en' },
        { id: 2, text: 'Spanish', link: 'es' },
        { id: 3, text: 'French', link: 'fr' },
        { id: 4, text: 'Russian', link: 'ru' },
        { id: 5, text: 'Japanese', link: 'jp' },
        { id: 6, text: 'Korean', link: 'ko' },
        // { id: 2, text: 'Turkish', link: '/tr' },
        // { id: 3, text: 'Portuguese (Brazil)', link: '/bp' },
        // { id: 4, text: 'Korean', link: '/ko' },
        // { id: 5, text: 'Arabic', link: '/ar' },
      ],
      playnav: [
        { id: 1, link: '/', text: 'New order', spanclass: 'fa-bags-shopping' },
        { id: 2, link: '/orders', text: 'Orders', spanclass: 'fa-ballot-check' },
        { id: 3, link: '/addfunds', text: 'Add funds', spanclass: 'fa-badge-dollar' },
        { id: 4, link: '/api', text: 'API', spanclass: 'fa-ballot-check' }
      ],
      language: ''
    }
  },
  created() {
    this.istoken = this.$cookies.get('token')
    // console.log(this.istoken,'appvue');

    this.showLangauge()
  },
  methods: {
    ...mapActions(['Categories2','Products','getsites']),
    showLangauge() {
      const currentLang = window.sessionStorage.getItem('lang') || 'zh'
      switch (currentLang) {
        case 'zh':
          this.language = 'Simplified Chinese'
          break
        case 'en':
          this.language = 'English'
          break
          case 'es':
          this.language = 'Spanish'
          break
          case 'fr':
          this.language = 'French'
          break
          case 'ru':
          this.language = 'Russian'
          break
          case 'jp':
          this.language = 'Japanese'
          break
          case 'ko':
          this.language = 'Korean'
          break
        // case 'ja':
        //   this.language = '日本语'
      }
    },
    // changeLangauge(lang) {
    //   this.$i18n.locale = lang
    //   window.sessionStorage.setItem('lang', lang)
    //   window.location.reload()
    // },
    logout() {
      this.$cookies.remove('token')
      window.location.reload();
      // this.$router.push('/')

      // this.$forceUpdate()

    },
    isgbnav() {
      $('.navbar-collapse').slideToggle()
    },
    ydnavgp() {
      $('.navbar-collapse').slideToggle()
    },
    yiru() {
      this.yichuruflag = !this.yichuruflag
    },
    yuyango(id, lang) {
      console.log(id, lang, 'langidd');
      let obj = this.navbarlink.find(val => val.id == id)
      this.navbarlink = this.navbarlink.filter(val => val.id != id)
      this.navbarlink = [...this.navbarlink, this.yuyanactive]
      this.yuyanactive = obj
      this.Categories2(lang)
      this.getsites(lang)
      this.Products({"num":this.$store.state.selecteds11,"lang":lang})
      this.$i18n.locale = lang
      window.sessionStorage.setItem('lang', lang)
      console.log(window.sessionStorage.getItem('lang'), '0000', this.$i18n);
      // window.location.reload()
      // this.$forceUpdate()

      // console.log(obj, 'obj');
      // console.log(this.navbarlink, 'this.navbarlink');

    }
  },
}

</script>
<style lang="less">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
.active {
  color: #008bff !important;
  background: transparent;
}

.files-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 29px;
}

.files-item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  line-height: 29px;
}

.files-item:last-child {
  margin-bottom: 0px;
}

.files-loader {
  margin: 0 0.2em 0 0;
  padding: 0;
  line-height: 0;
  vertical-align: middle;
  font-size: 24px;
  display: flex;
  align-items: center;
  min-height: 29px;

}

.files-loader__svg {
  display: block;
  width: 1em;
  height: 1em;
  fill: transparent;
  transform: rotate(180deg);
  margin: 0px 8px;
}

.files-loader__svg-circle {
  fill: transparent;
}

.files-loader__svg-progress {
  transition: all 0.4s;
}

// }
</style>
