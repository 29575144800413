import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import NewOrder from '../views/NewOrder.vue'
import AddFunds from '../views/AddFunds.vue'
import OrdersView from '../views/OrdersView.vue'
import SigNup from '../views/SigNup.vue'
import ResetpassWord from '../views/ResetpassWord.vue'
import cookies from 'vue-cookies'
import {
  Message
} from 'element-ui';
Vue.prototype.$message = Message;
let istoken = cookies.get('token')
// console.log( this.$cookies.get('token'),'路由');
// console.log(cookies.get('token'), 'get');
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: cookies.get('token') ? 'neworder' : 'login',
    component: cookies.get('token') ? NewOrder : LoginView
    // redirect: istoken? 'NewOrder':'login'
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component:LoginView
  // },
  {
    path: '/api',
    name: 'api',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/ApiView.vue')
  },
  // {
  //   path: '/neworder',
  //   name: 'neworder',
  //   component: NewOrder
  // },
  {
    path: '/addfunds',
    name: 'addfunds',
    component: AddFunds
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView
  },
  {
    path: '/signup',
    name: 'signup',
    component: SigNup
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetpassWord
  },
]

const router = new VueRouter({
  routes
})
let tokenlinklist = ['/orders', '/addfunds', '/neworder']
router.beforeEach((to, from, next) => {
  // console.log(to, from);
  // to是导航所要跳转到的组件
  // from是从那个组件过来
  // next()满足条件放行
  // 当用户没有登录时则回到登录页，有登录中则直接放行
  // 当访问的路径中含有main则验证是否登录
  // console.log(to.path);
// console.log(tokenlinklist,'tokenlinklisttokenlinklist');
tokenlinklist.map(val=>{
  // console.log(val);
  if (to.path.indexOf(val) != -1) {
    // 后台  需要验证
    // if (localStorage.getItem('uname')) {
    if (cookies.isKey('token')) {

      // Message({
      //   message: '恭喜你,登录成功',
      //   type: 'success'
      // })
      next()
    } else {
      Message({
        message: '请不要非法进入后台',
        type: 'warning'
      })
      next('/')

    }
  } else {
    next() //不是去后台则直接放行
  }
})
  
})
export default router