// import request from "@/utils/request";//记得引入进来
import axios from 'axios'
 
export function login(data) {
    return axios.post('/user/login',data)
}

export function register(data) {
    return axios.post('/user/register',data)
}

export function getCategories(data) {
    return axios.get('/getCategory?site=3&lang='+data.lang)
}

export function getProducts(data) {
    return axios.get('/getProduct?categoryId='+data.siteId+'&lang='+data.lang)
}
export function getSite(data) {
    return axios.get('/getSite?siteId=3&lang='+data.lang)
}