// module.exports = {
export default {
    userManager: {
        blocksignin__title: 'SMM 서비스의 왕. 온라인 #1 SMM 패널',
        blocksignin__description: '저렴한 가격으로 팔로워, 좋아요, 조회수 및 기타 서비스 제공',
        searchBtn: '검색',
        Password: '비밀번호',
        isPassword: '비밀번호 확인',
        Logout: '로그아웃',
        account: '계정',
        username: '사용자 이름',
        email: '이메일',
        departmentFullPath: '부서 전체 경로',
        positionName: '직책 이름',
        status: '상태',
        Signup: '가입',
        Signin: '로그인',
        operate: '조작',
        api: 'API (응용 프로그램 인터페이스)',
        // famedaltext1:'Best quality',
        famedaltext1: '최고의 품질',

        famedaltext2: "우리는 고객에게 최고의 SMM 서비스만 제공합니다.",

        fareceipttext1: '다양한 결제 방법',

        fareceipttext2: '우리는 다양한 결제 방법을 선택할 수 있습니다.',

        fahandholdingusd1: '슈퍼 혜택',

        fahandholdingusd2: '우리는 모든 제품의 낮은 가격을 유지하고 고객을 만족시킵니다.',

        fashippingfast1: '빠른 배송',

        fashippingfast2: '우리 플랫폼에 있는 당신의 주문은 매우 빠른 속도로 배달될 것입니다.',

        textblock__title: '어디서부터?',

        textblock__description: '우리 패널에서 주문을 시작하시겠습니까?다음 네 가지 간단한 단계를 따르십시오.',
        contentlist: [{

            id: 1,
            
            title:'등록 및 로그인',
            
            desc:'우선 등록하고 로그인해야 합니다.'
            
            },
            
            {
            
            id: 2,
            
            title:'자금 추가',
            
            desc:'다음 결제 방법을 선택하고 자금을 계좌에 입금합니다.'
            
            },
            
            {
            
            id: 3,
            
            title:'서비스 선택',
            
            desc:'소셜 미디어에서 더 많은 노출을 얻기 위해 주문합니다.'
            
            },
            
            {
            
            id: 4,
            
            title:'뛰어난 효과',
            
            desc:'주문이 준비되면 결과에 깊은 인상을 받을 것입니다.'
            
            }
            
            ],
        Customerexperience: '고객 경험',
        swiperlist: [{
            "id": 1,
            "title": "리처드 블랙",
            "desc": "솔직히 말해서, 이 패널에서 SMM 서비스를 주문할 때 이렇게 좋은 결과를 기대하지 않았어요. 매우 깊은 인상을 받았습니다! 분명히 더 주문할 거에요."
            },
            
            {
            "id": 2,
            "title": "루카스 우드",
            "desc": "SMM 전문가들은 더 쉽게 일할 방법을 끊임없이 찾고 있어, 여기 친구들아! 말하고 싶어, 이 SMM 패널은 당신의 작업을 순조롭게 만들 거에요. 여기 서비스는 정말 저렴해서 놀라게 될 거예요."
            },
            
            {
            "id": 3,
            "title": "니콜 베이서스",
            "desc": "Instagram 계정이 노출을 더 많이 받는 데 도움이 되는 방법을 찾고 있다면, 이 SMM 패널은 틀림없어요! 가장 좋은 건 모든 게 매우 저렴하다는 거예요. 원하는 결과를 얻기 위해 큰 돈을 쓸 필요가 없어요."
            }

        ],
        stylefaqtitle:'가장 자주 묻는 질문',

        stylefaqdesc:'우리 그룹에서 가장 인기 있는 5개 질문에 대한 답을 보십시오.',
        MostAskedQuestions: [{

            id: 1,
            
            text1: "사람들이 SMM 패널을 찾는 이유는??",
            
            text2: "SMM 패널은 다양한 유형의 SMM 서비스를 제공하는 온라인 스토어입니다.",
            
            flag: false,
            
            },
            
            
            
            {
            
            id: 2,
            
            text1: "패널에서 찾을 수 있는 SMM 서비스는 무엇입니까?",
            
            text2: "우리 패널에는 좋아하는 사람, 관심 있는 사람, 보기 등 다양한 유형의 SMM 서비스가 제공됩니다.",
            
            flag: false,
            
            },
            
            {
            
            id: 3,
            
            text1: "이곳에서 판매하는 SMM 서비스를 사용하는 것이 안전합니까?",
            
            text2: "네, 이것은 절대적으로 안전하며 계정은 일시 중지되지 않습니다.",
            
            flag: false,
            
            },
            
            {
            
            id: 4,
            
            text1: "대량 주문 기능은 무엇입니까?",
            
            text2: "한 번에 여러 개의 주문이 필요할 때 대량 주문 기능이 큰 도움이 됩니다.",
            
            flag: false,
            
            },
            
            {
            
            id: 5,
            
            text1: "점적 관개는 어떻게 작동합니까?",
            
            text2: '당신은 심지어 소셜 미디어 계정의"유기적인"성장을 모방할 수 있습니다 - 점적관개가 당신에게 도움을 줄 것입니다!IG 게시물에 좋아요 1000개를 주문하고 싶다면 좋아요 1000개를 즉시 받을 수도 있고 단계적으로 달성할 수도 있습니다. 예를 들어 좋아요 100개/일, 10일 지속.',
            
            flag: false,
            
            },
        ],
        banquan: '© 저작권 SMM918.COM.모든 권리를 보유합니다.',

        Rememberme:'비밀번호 기억하기',
        
        Forgotpassword:'비밀번호를 잊으셨습니까?',
        
        Donothaveanaccount:'계정이 없습니까?',
        
        Donothaveanaccountyou:'이미 계정이 있습니까?',
        
        blockfeatures__title:'SMM 서비스를 주문해야 하는 이유',
        
        blockfeatures__description:'온라인 이미지를 빠르고 효과적으로 구축할 수 있도록 도와드립니다.',
        playnav: [{

            id: 1,
            
            link: '/',
            
            text:'새 주문',
            
            spanclass: 'fa-bags-shopping'
            
            },
            
            {
            
            id: 2,
            
            link: '/orders',
            
            text:'주문',
            
            spanclass: 'fa-ballot-check'
            
            },
            
            {
            
            id: 3,
            
            link: '/addfunds',
            
            text:'자금 추가',
            
            spanclass: 'fa-badge-dollar'
            
            },
            
            {
            
            id: 4,
            
            link: '/api',
            
            text:'응용 프로그램 인터페이스',
            
            spanclass: 'fa-ballot-check'
            
            }
        ],
        Category:'카테고리',

Service:'서비스',

Charge:'책임',

neworder__contenttitle:'주문 팁',

neworder__contenttext:'주문을 하기 전에 설명을 자세히 읽어 주십시오.',

Submit:'제출',
ordertab: [{

    id: 1,
    
    text:'모든',
    
    link: '/orders'
    
    },
    
    {
    
    id: 2,
    
    text:'중지',
    
    link: '/orders/pending'
    
    },
    
    {
    
    id: 3,
    
    text:'진행 중',
    
    link: '/orders/inprogress'
    
    },
    
    {
    
    id: 4,
    
    text:'완료',
    
    link: '/orders/completed'
    
    },
    
    {
    
    id: 5,
    
    text:'부분적',
    
    link: '/orders/partial'
    
    },
    
    {
    
    id: 6,
    
    text:'가공',
    
    link: '/orders/processing'
    
    },
    
    {
    
    id: 7,
    
    text:'취소됨',
    
    link: '/orders/canceled'
    
    },
    
    ],
    data:'날짜',

    link:'링크',
    
    Startcount:'시작 카운트',
    
    Quantity:'수',
    
    // Service: 'Service',
    
    Description:'설명',
    
    Amount:'양',
    
    Status:'상태',
    
    Remains:'잔여물',
    
    Method:'메소드',
    
    pay:'지불',

    }
}