// module.exports = {
export default {
    userManager: {
        blocksignin__title: 'SMM服务之王。#1 SMM面板在线',
        blocksignin__description: '我们提供超便宜的关注者，喜欢，观点和其他服务',
        searchBtn: '查询',
        Password: '密码',
        isPassword: '确认密码',
        Logout: '注销',
        account: '账号',
        username: '用户名',
        email: '电子邮件',
        departmentFullPath: '部门全称',
        positionName: '岗位名称',
        status: '状态',
        Signup: '注册',
        Signin: '登录',
        operate: '操作',
        api: '应用程序接口',
        // famedaltext1:'Best quality',
        famedaltext1: '最佳品质',
        famedaltext2: '我们只为客户提供最好的SMM服务。',
        fareceipttext1: '多种付款方式',
        fareceipttext2: '我们提供多种付款方式供选择。',
        fahandholdingusd1: '超级实惠',
        fahandholdingusd2: '我们保持所有产品的低价，让客户满意。',
        fashippingfast1: '快速配送',
        fashippingfast2: '您在我们平台上的订单将会以超快的速度送达。',
        textblock__title: '从哪里开始？',
        textblock__description: '想开始在我们的面板上下订单吗？遵循以下 4 个简单步骤。',
        contentlist: [{
                id: 1,
                title: '注册和登录',
                desc: '首先，您需要注册并登录。'
            },
            {
                id: 2,
                title: '添加资金',
                desc: '接下来，选择一种付款方式，并将资金存入您的账户。'
            },
            {
                id: 3,
                title: '选择服务',
                desc: '下订单，准备在社交媒体上获得更多曝光。'
            },
            {
                id: 4,
                title: '出色的效果',
                desc: '当您的订单准备好时，您一定会对结果感到印象深刻。'
            }
        ],
        Customerexperience: '客户体验',
        swiperlist: [{
                id: 1,
                title: '理查德·布莱克',
                desc: `老实说，我什至没想到在这个面板上订购SMM服务的结果会这么好，我印象非常深刻！肯定会很快订购更多。`
            },
            {
                id: 2,
                title: '卢卡斯·伍德',
                desc: `SMM专家不断寻找使他们的工作更轻松的方法，就是这样，伙计们！我告诉你，这个SMM面板将使您的工作顺利进行。这里的服务非常便宜，你会被吹走。`
            },
            {
                id: 3,
                title: '妮可·威瑟斯',
                desc: `如果您正在寻找一种方法来帮助您的 Instagram 帐户获得更多曝光率，那么此 SMM 面板不会出错！最好的事情是一切都超级便宜，您无需花费大量金钱即可获得所需的结果。`
            },

        ],
        stylefaqtitle: '最常问的问题',
        stylefaqdesc: '在我们的小组中查看前 5 个最受欢迎的问题的答案。',
        MostAskedQuestions: [{
                id: 1,
                text1: "人们为什么寻找SMM面板？?",
                text2: "SMM面板是提供不同类型SMM服务的在线商店。",
                flag: false,
            },

            {
                id: 2,
                text1: "我可以在你们的面板上找到哪些SMM服务？",
                text2: "我们的面板上提供多种类型的SMM服务：喜欢，关注者，视图等。",
                flag: false,
            },
            {
                id: 3,
                text1: "使用这里出售的SMM服务是否安全？",
                text2: "是的，这绝对是安全的，您的帐户不会被暂停。",
                flag: false,
            },
            {
                id: 4,
                text1: "批量订单功能有什么用？",
                text2: "当您需要一次下多个订单时，批量订单功能会有很大帮助。",
                flag: false,
            },
            {
                id: 5,
                text1: "滴灌是如何工作的？",
                text2: `您甚至可以模仿社交媒体帐户的“有机”增长 - 滴灌将为您提供帮助！假设您想在 IG 帖子上订购 1000 个赞：您可以立即获得所有 1000 个赞，也可以逐步实现，例如，100 个赞/天，持续 10 天。`,
                flag: false,
            },
        ],
        banquan: '© 版权 SMM918.COM。保留所有权利。',
        Rememberme: '记住密码',
        Forgotpassword: '忘记密码？',
        Donothaveanaccount: '没有账户？',
        Donothaveanaccountyou: '已有账户？',
        blockfeatures__title: '为什么您应该订购我们的SMM服务',
        blockfeatures__description: '让我们帮助您快速有效地建立您的在线形象。',
        playnav: [{
                id: 1,
                link: '/',
                text: '新订单',
                spanclass: 'fa-bags-shopping'
            },
            {
                id: 2,
                link: '/orders',
                text: '订单',
                spanclass: 'fa-ballot-check'
            },
            {
                id: 3,
                link: '/addfunds',
                text: '添加资金',
                spanclass: 'fa-badge-dollar'
            },
            {
                id: 4,
                link: '/api',
                text: '应用程序接口',
                spanclass: 'fa-ballot-check'
            }
        ],
        Category: '类别',
        Service: '服务',
        Charge: '负责',
        neworder__contenttitle: '下单小贴士',
        neworder__contenttext: '在下订单之前，请务必仔细阅读说明。',
        Submit: '提交',
        ordertab: [{
                id: 1,
                text: '所有',
                link: '/orders'
            },
            {
                id: 2,
                text: '挂起',
                link: '/orders/pending'
            },
            {
                id: 3,
                text: '进行中',
                link: '/orders/inprogress'
            },
            {
                id: 4,
                text: '完成',
                link: '/orders/completed'
            },
            {
                id: 5,
                text: '部分的',
                link: '/orders/partial'
            },
            {
                id: 6,
                text: '加工',
                link: '/orders/processing'
            },
            {
                id: 7,
                text: '已取消',
                link: '/orders/canceled'
            },
        ],
        data: '日期',
        link: '链接',
        Startcount: '开始计数',
        Quantity: '数量',
        // Service: 'Service',
        Description:'描述',
        Amount:'量',
        Status: '状态',
        Remains: '残余物',
        Method:'方法',
        pay:'支付',
        
    }
}