export default {
  userManager: {
    blocksignin__title: 'SMM Service kings. #1 SMM Panel Online.',
    blocksignin__description: 'We provide super cheap followers, likes, views and other services.',
    searchBtn: 'Search',
    account: 'Account',
    username: 'Username',
    isPassword: 'Confirm password',
    Logout: 'Logout',
    Password: 'Password',
    email: 'Email',
    departmentFullPath: 'DepartmentFullPath',
    positionName: 'PositionName',
    status: 'Status',
    Signup: 'Sign up',
    Signin: 'Sign in',
    operate: 'Operate',
    api: 'API',
    famedaltext1: 'Best quality',
    famedaltext2: 'We provide only the best SMM services for our customers.',
    fareceipttext1: 'Different payment methods',
    fareceipttext2: 'We provide different payment methods to pick from.',
    fahandholdingusd1: 'Super affordable',
    fahandholdingusd2: 'We keep all our prices low to make our customers happy.',
    fashippingfast1: 'Extra quick delivery',
    fashippingfast2: 'Your orders on our panel will be delivered super fast.',
    textblock__title: 'Where to begin?',
    textblock__description: 'Want to start placing orders on our panel? Follow these 4 easy steps.',
    contentlist: [{
        id: 1,
        title: 'Sign up and log in',
        desc: 'The first thing you need to do is to sign up and then log in.'
      },
      {
        id: 2,
        title: 'Add funds',
        desc: 'TNext, choose a payment method and deposit fundsto your account.'
      },
      {
        id: 3,
        title: 'Choose services',
        desc: 'Place orders and get ready to receive morepublicity on social media.'
      },
      {
        id: 4,
        title: 'Superb results',
        desc: 'You will definitely be impressed with theresults when your order is ready.'
      }
    ],
    Customerexperience: 'Customer experience',
    swiperlist: [{
        id: 1,
        title: 'Richard Black',
        desc: `To be honest, I didn't even expect that the
      results of ordering SMM services on this panel
      would be so good, I'm very impressed!
      Definitely ordering more soon.`
      },
      {
        id: 2,
        title: 'Lucas Wood',
        desc: `SMM specialists constantly look for ways to
      make their jobs easier and THIS IS IT, GUYS!
      This SMM panel will make your job run
      smoothly, I'm telling you. Services here are
      so cheap that you will be blown away.`
      },
      {
        id: 3,
        title: 'Nicole Withers',
        desc: `If you're looking for a way to help your
      Instagram account more exposure, you can't go
      wrong with this SMM panel! And the best thing
      is that everything is super cheap, you don't
      need to spend a ton of money to get the
      results you want.`
      },

    ],
    stylefaqtitle: 'Most Asked Questions',
    stylefaqdesc: 'Check out answers to top 5 most popular questions on ourpanel.',
    MostAskedQuestions: [{
        id: 1,
        text1: "Why do people look for SMM panels?",
        text2: "SMM panels are online stores that offer SMM services of different kinds.",
        flag: false,
      },

      {
        id: 2,
        text1: "What SMM services can I find on your panel?",
        text2: "There are many types of SMM services available on our panel: likes, followers, views, etc.",
        flag: false,
      },
      {
        id: 3,
        text1: "Is it safe to use SMM services sold here?",
        text2: "Yes, its definitely safe, your accounts wont be suspended.",
        flag: false,
      },
      {
        id: 4,
        text1: "What is the mass order feature for?",
        text2: "The mass order feature helps a lot when you need to place several orders at once.",
        flag: false,
      },
      {
        id: 5,
        text1: "How does Drip-feed work?",
        text2: `You can even imitate the "organic" growth of social media accounts — and Drip-feed will
              help you with that! Let's say you want to place an order for 1000 likes on your IG post: you
              can get all 1000 right away or make it happen gradually, for example, 100 likes/day for 10
              days.`,
        flag: false,
      },
    ],
    banquan: '© Copyright SMM918.COM. All Rights Reserved.',
    Rememberme: 'Remember me',
    Forgotpassword: 'Forgot password?',
    Donothaveanaccount: 'Do not have an account?',
    Donothaveanaccountyou: 'Already have an account?',
    blockfeatures__title: 'Why you should order our SMM services',
    blockfeatures__description: 'Let us help you build your online presence quickly and efficiently.',
    playnav: [{
        id: 1,
        link: '/',
        text: 'New order',
        spanclass: 'fa-bags-shopping'
      },
      {
        id: 2,
        link: '/orders',
        text: 'Orders',
        spanclass: 'fa-ballot-check'
      },
      {
        id: 3,
        link: '/addfunds',
        text: 'Add funds',
        spanclass: 'fa-badge-dollar'
      },
      {
        id: 4,
        link: '/api',
        text: 'API',
        spanclass: 'fa-ballot-check'
      }
    ],
    Category: 'Category',
    Service: 'Service',
    Charge: 'Charge',
    neworder__contenttitle: 'Tips of placingorders',
    neworder__contenttext: 'Make sure read the description carefully before placing an order.',
    Submit: 'Submit',
    ordertab: [{
        id: 1,
        text: 'All',
        link: '/orders'
      },
      {
        id: 2,
        text: 'Pending',
        link: '/orders/pending'
      },
      {
        id: 3,
        text: 'In progress',
        link: '/orders/inprogress'
      },
      {
        id: 4,
        text: 'Completed',
        link: '/orders/completed'
      },
      {
        id: 5,
        text: 'Partial',
        link: '/orders/partial'
      },
      {
        id: 6,
        text: 'Processing',
        link: '/orders/processing'
      },
      {
        id: 7,
        text: 'Canceled',
        link: '/orders/canceled'
      },
    ],
    data: 'data',
    link: 'link',
    Startcount: 'Start count',
    Quantity: 'Quantity',
    // Service: 'Service',
    Description:'Description',
    Amount:'Amount',
    Status: 'Status',
    Remains: 'Remains',
    Method: 'Method',
    pay: 'Pay',
    
  }
}