// module.exports = {
export default {
    userManager: {
        blocksignin__title: 'Король SMM-услуг. #1 SMM-панель онлайн',
        blocksignin__description: 'Мы предоставляем очень дешевых подписчиков, лайки, просмотры и другие услуги',
        searchBtn: 'Поиск',
        Password: 'Пароль',
        isPassword: 'Подтверждение пароля',
        Logout: 'Выход',
        account: 'Аккаунт',
        username: 'Имя пользователя',
        email: 'Электронная почта',
        departmentFullPath: 'Полное название отдела',
        positionName: 'Название должности',
        status: 'Статус',
        Signup: 'Регистрация',
        Signin: 'Вход',
        operate: 'Операция',
        api: 'API (Интерфейс приложения)',

        // famedaltext1:'Best quality',
        famedaltext1: 'Лучшее качество',
        famedaltext2: 'Мы предоставляем только лучшие услуги SMM для клиентов.',
        fareceipttext1: 'Различные способы оплаты',
        fareceipttext2: 'У нас есть различные способы оплаты на выбор.',
        fahandholdingusd1: 'Очень доступные цены',
        fahandholdingusd2: 'Мы поддерживаем низкие цены на все наши товары, чтобы удовлетворить клиентов.',
        fashippingfast1: 'Быстрая доставка',
        fashippingfast2: 'Ваши заказы на нашей платформе будут доставлены супер быстро.',
        textblock__title: 'С чего начать?',
        textblock__description: 'Хотите начать делать заказы на нашей панели? ',
        contentlist: [{
            "id": 1,
            "title": "Регистрация и вход",
            "desc": "Сначала вам нужно зарегистрироваться и войти в систему.",
            },
            
            {
            "id": 2,
            "title": "Добавление средств",
            "desc": "Затем выберите способ оплаты и внесите средства на свой счет.",
            },
            
            {
            "id": 3,
            "title": "Выбор услуги",
            "desc": "Сделайте заказ и готовьтесь к большей экспозиции в социальных сетях."
            },
            
            {
            "id": 4,
            "title": "Отличные результаты",
            "desc": "Когда ваш заказ будет готов, вы обязательно останетесь впечатлены результатами.",
            }
        ],
        Customerexperience: 'Клиентский опыт ',
        swiperlist: [{
            "id": 1,
            "title": "Ричард Блэк",
            "desc": "Честно говоря, я даже не ожидал, что результаты заказа услуг SMM на этой панели будут такими хорошими. Я очень впечатлен! Обязательно сделаю еще заказы."
            }
            ,
            {
            "id": 2,
            "title": "Лукас Вуд",
            "desc": "Специалисты по SMM всегда ищут способы сделать свою работу проще, и вот он, парни! Скажу вам, эта панель SMM сделает вашу работу легкой. Здесь услуги очень дешевые, вы будете поражены."
            },
            
            {
            "id": 3,
            "title": "Николь Вейсерс",
            "desc": "Если вы ищете способ увеличить видимость своего аккаунта Instagram, то эта панель SMM - ваш выбор! Лучшее в этом - все это по супер-доступным ценам, вам не нужно тратить много денег, чтобы достичь нужных результатов."
            },

        ],
        stylefaqtitle: ' Часто задаваемые вопросы,',
        stylefaqdesc: ' Посмотрите ответы на первые 5 самых популярных вопросов в нашей группе',
        MostAskedQuestions: [{
            "id": 1,
            "text1": "Почему люди ищут панель SMM?",
            "text2": "Панель SMM - это онлайн-магазин, предоставляющий различные виды услуг SMM.",
            "flag": false
            }
            ,
            {
            "id": 2,
            "text1": "Какие виды услуг SMM я могу найти на вашей панели?",
            "text2": "На нашей панели предоставляются различные виды услуг SMM, такие как лайки, подписчики, просмотры и многое другое.",
            "flag": false
            },
            
            {
            "id": 3,
            "text1": "Являются ли услуги SMM, продаваемые здесь, безопасными?",
            "text2": "Да, они абсолютно безопасны, ваш аккаунт не будет приостановлен.",
            "flag": false
            },
            
            {
            "id": 4,
            "text1": "Зачем нужна функция массового заказа?",
            "text2": "Функция массового заказа очень полезна, когда вам нужно сделать несколько заказов одновременно.",
            "flag": false
            },
            
            {
            "id": 5,
            "text1": "Как работает постепенное увеличение (следование)?",
            "text2": "Вы можете имитировать 'органический' рост вашего аккаунта в социальных медиа с помощью постепенного увеличения! Например, если вы хотите заказать 1000 лайков к посту в Instagram, вы можете получить их все сразу или постепенно, например, 100 лайков в день в течение 10 дней.",
            "flag": false
            },
        ],
        banquan: ' ©   Авторское право SMM918.COM.  Сохранить все права ',
        Rememberme: 'Запомни пароль',
        Forgotpassword: 'Забыть пароль?',
        Donothaveanaccount: 'Нет учетной записи?',
        Donothaveanaccountyou: 'Уже есть счет?',
        blockfeatures__title: 'Почему вы должны заказать наши услуги SMM',
        blockfeatures__description: ' Давайте поможем вам быстро и эффективно создать свой имидж в онлайне',
        playnav: [{
                id: 1,
                link: '/',
                text: 'Новый заказ',
                spanclass: 'fa-bags-shopping'
            },
            {
                id: 2,
                link: '/orders',
                text: 'Заказ',
                spanclass: 'fa-ballot-check'
            },
            {
                id: 3,
                link: '/addfunds',
                text: 'Добавить средства',
                spanclass: 'fa-badge-dollar'
            },
            {
                id: 4,
                link: '/api',
                text: 'Интерфейс приложения"',
                spanclass: 'fa-ballot-check'
            }
        ],
        Category: 'Категория',
        Service: 'Услуга',
        Charge: 'Стоимость',
        neworder__contenttitle: 'Советы по заказу',
        neworder__contenttext: 'Перед размещением заказа обязательно внимательно прочтите инструкцию.',
        Submit: 'Отправить',
        ordertab: [{
                id: 1,
                text: 'Все',
                link: '/orders'
            },
            {
                id: 2,
                text: 'Повесить',
                link: '/orders/pending'
            },
            {
                id: 3,
                text: 'В процессе',
                link: '/orders/inprogress'
            },
            {
                id: 4,
                text: 'Завершено',
                link: '/orders/completed'
            },
            {
                id: 5,
                text: 'Частично',
                link: '/orders/partial'
            },
            {
                id: 6,
                text: 'Обработка',
                link: '/orders/processing'
            },
            {
                id: 7,
                text: 'Отменено',
                link: '/orders/canceled'
            },
        ],
        data: 'Дата',
        link: 'Ссылка',
        Startcount: 'Начальное количество',
        Quantity: 'Количество',
        Description: 'Описание',
        Amount: 'Сумма',
        Status: 'Статус',
        Remains: 'Остаток',
        Method: 'Метод',
        pay: 'Оплата',

    }
}