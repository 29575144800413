// module.exports = {
export default {
    userManager: {
        blocksignin__title: `rey de los servicios smm'. 1 panel SMM en línea `,
        blocksignin__description: `ofrecemos atención súper barata, como, opiniones y otros servicios`,
        searchBtn: 'consulta',
        Password: 'contraseña',
        isPassword: 'confirme la contraseña',
        Logout: 'cancelar',
        account: 'cuenta',
        username: 'nombre de usuario',
        email: `correo electrónico`,
        departmentFullPath: 'nombre completo del departamento',
        positionName: 'nombre del puesto',
        status: 'estado',
        Signup: 'registrado',
        Signin: 'iniciarsesión',
        operate: 'operación',
        api: 'interfaz de aplicación',
        // famedaltext1:'Best quality',
        famedaltext1: 'la mejor calidad',
        famedaltext2: 'solo ofrecemos el mejor servicio SMM a nuestros clientes',
        fareceipttext1: 'múltiples métodos de pago',
        fareceipttext2: 'ofrecemos una variedad de métodos de pago para elegir',
        fahandholdingusd1: 'súper asequible',
        fahandholdingusd2: 'mantenemos el precio bajo de todos los productos y satisfacemos a los clientes',
        fashippingfast1: 'distribución rápida',
        fashippingfast2: 'sus pedidos en nuestra plataforma llegarán a un ritmo súper rápido',
        textblock__title: '¿ por dónde empezar?',
        Ttextblock__description: '¿ quieres comenzar a hacer pedidos en nuestro panel? Siga estos 4 pasos simples. ',
        contentlist: [{
                id: 1,
                title: 'Registro y inicio de sesión',
                desc: 'En primer lugar, debe registrarse e iniciar sesión.'
            },

            {
                id: 2,
                title: 'Agregar fondos',
                desc: 'A continuación, elija un método de pago y deposite fondos en su cuenta.'
            },

            {
                id: 3,
                title: 'Seleccionar servicio',
                desc: 'Realice un pedido para aumentar su exposición en las redes sociales.'
            },

            {
                id: 4,
                title: 'Resultados impresionantes',
                desc: 'Cuando su pedido esté listo, seguramente quedará impresionado con los resultados.'
            },
        ],
        Customerexperience: 'Experiencia del cliente',
        swiperlist: [{
                id: 1,
                title: 'Richard Black',
                desc: 'Honestamente, no esperaba que los resultados de ordenar servicios SMM en este panel fueran tan buenos. ¡Estoy muy impresionado! Definitivamente, pediré más pronto.',
            },
            {
                id: 2,
                title: 'Lucas Wood',
                desc: 'Los expertos en SMM siempre buscan formas de hacer su trabajo más fácil, ¡y esto es justo lo que necesitas! Te lo digo, este panel SMM hará que tu trabajo sea mucho más fluido. Los servicios aquí son muy asequibles, te sorprenderán.',
            },
            {
                id: 3,
                title: 'Nicole Weathers',
                desc: 'Si estás buscando una forma de aumentar la exposición de tu cuenta de Instagram, ¡este panel SMM es la elección correcta! Lo mejor de todo es que todo es súper económico, no necesitas gastar mucho dinero para obtener los resultados que deseas.',
            }
        ],
        stylefaqtitle: 'Preguntas más frecuentes',
        stylefaqdesc: 'Consulta las respuestas a las 5 preguntas más populares en nuestro grupo.',
        MostAskedQuestions: [{
                id: 1,
                text1: "¿Por qué la gente busca un panel SMM?",
                text2: "Un panel SMM es una tienda en línea que ofrece diversos tipos de servicios SMM.",
                flag: false,
            },
            {
                id: 2,
                text1: "¿Qué tipos de servicios SMM puedo encontrar en su panel?",
                text2: "En nuestro panel, ofrecemos una variedad de servicios SMM, como likes, seguidores, vistas, entre otros.",
                flag: false,
            },
            {
                id: 3,
                text1: "¿Son seguros los servicios SMM que se venden aquí?",
                text2: "Sí, son completamente seguros; su cuenta no será suspendida.",
                flag: false,
            },
            {
                id: 4,
                text1: "¿de qué sirve la función de pedido por lotes?",
                text2: "a función de pedido por lotes ayuda mucho cuando necesita hacer varios pedidos a la vez。",
                flag: false,
            },
            {
                id: 5,
                text1: "¿cómo funciona el riego por goteo?",
                text2: `¡Interfaz de aplicación incluso puede imitar el crecimiento "orgánico" de las cuentas de redes sociales - ¡ el riego por goteo le ayudará! Supongamos que desea ordenar 1000 me gusta en la publicación de ig: puede obtener los 1000 me gusta de inmediato o implementarlos gradualmente, por ejemplo, 100 me gusta / día, que dura 10 días.`,
                flag: false,
            },
        ],

        banquan: "© Derechos de autor SMM918.COM. Todos los derechos reservados.",
        Rememberme: "Recordarme",
        Forgotpassword: "¿Olvidaste tu contraseña?",
        Donothaveanaccount: "¿No tienes una cuenta?",
        Donothaveanaccountyou: "¿Ya tienes una cuenta?",
        blockfeatures__title: "¿Por qué deberías ordenar nuestros servicios de SMM?",
        blockfeatures__description: "Permítenos ayudarte a construir rápidamente tu presencia en línea de manera efectiva.",


        playnav: [{
                id: 1,
                link: '/',
                text: 'Nuevos pedidos',
                spanclass: 'fa-bags-shopping'
            },
            {
                id: 2,
                link: '/orders',
                text: 'Pedidos',
                spanclass: 'fa-ballot-check'
            },
            {
                id: 3,
                link: '/addfunds',
                text: 'Añadir fondos',
                spanclass: 'fa-badge-dollar'
            },
            {
                id: 4,
                link: '/api',
                text: 'Interfaz de aplicación',
                spanclass: 'fa-ballot-check'
            }
        ],
        Category: "Categoría",
        Service: "Servicio",
        Charge: "Cargo",
        neworder__contenttitle: 'Consejos para hacer un pedido',
        neworder__contenttext: 'Antes de realizar un pedido, asegúrate de leer detenidamente las instrucciones.',
        Submit: "Enviar",
        ordertab: [{
                id: 1,
                text: 'Todos',
                link: '/orders'
            },
            {
                id: 2,
                text: 'Pendientes',
                link: '/orders/pending'
            },
            {
                id: 3,
                text: 'En curso',
                link: '/orders/inprogress'
            },
            {
                id: 4,
                text: 'Completados',
                link: '/orders/completed'
            },
            {
                id: 5,
                text: 'Parciales',
                link: '/orders/partial'
            },
            {
                id: 6,
                text: 'Procesando',
                link: '/orders/processing'
            },
            {
                id: 7,
                text: 'Cancelados',
                link: '/orders/canceled'
            },
        ],
        data: "Fecha",
        link: "Enlace",
        Startcount: "Inicio del conteo",
        Quantity: "Cantidad",
        Description: "Descripción",
        Amount: "Monto",
        Status: "Estado",
        Remains: "Restante",
        Method: "Método",
        pay: "Pagar"

    }
}