// module.exports = {
export default {
    userManager: {
        blocksignin__title: 'Le roi des services SMM. #1 Plateforme SMM en ligne',
        blocksignin__description: "Nous proposons des abonnés, des likes, des vues et d'autres services à des prix très bas",
        searchBtn: 'Rechercher',
        Password: 'Mot de passe',
        isPassword: 'Confirmer le mot de passe',
        Logout: 'Se déconnecter',
        account: 'Compte',
        username: "Nom d'utilisateur",
        email: "Adresse e-mail",
        departmentFullPath: 'Nom complet du département',
        positionName: 'Nom du poste',
        status: 'Statut',
        Signup: "S'inscrire",
        Signin: "Seconnecter",
        operate: 'Opérer',
        api: "Interface de programmation d'application (API)",
        // famedaltext1:'Best quality',
        famedaltext1: ' meilleure qualité ',
        famedaltext2: "Nous offrons seulement le meilleur service SMM à nos clients.",
        fareceipttext1: "plusieurs modes de paiement",
        fareceipttext2: "Nous offrons un large choix de méthodes de paiement.",
        fahandholdingusd1: ' super abordable ',
        fahandholdingusd2: 'nous gardons tous les produits à bas prix pour la satisfaction du client',
        fashippingfast1: ' livraison rapide ',
        fashippingfast2: 'votre commande sur notre plateforme sera livrée très rapidement',
        textblock__title: "par où commencer?",
        textblock__description: 'Voulez - vous commencer à passer des commandes sur notre panneau? Suivez ces 4 étapes simples. ',
        contentlist: [{
            id: 1,
            title: "Inscription et connexion",
            desc: "Tout d'abord, vous devez vous inscrire et vous connecter."
            }
            ,
            {
            id: 2,
            title: "Ajout de fonds",
            desc: "Ensuite, choisissez un mode de paiement et déposez de l'argent sur votre compte."
            }
            ,
            {
            id: 3,
            title: "Choix des services",
            desc: "Passez commande et préparez-vous à gagner plus d'exposition sur les médias sociaux."
            }
            ,
            {
            id: 4,
            title: "Résultats exceptionnels",
            desc: "Lorsque votre commande est prête, vous serez certainement impressionné par les résultats."
            }
        ],
        Customerexperience: 'Expérience client',
        swiperlist: [{
            id: 1,
            title: "Richard Black",
            desc: "Pour être honnête, je n'aurais jamais pensé que les résultats de la commande de services SMM sur ce tableau seraient si bons. J'en suis profondément impressionné ! Je vais certainement commander plus bientôt."
            }
            ,
            {
            id: 2,
            title: "Lucas Wood",
            desc: "Les experts en SMM cherchent constamment des moyens de simplifier leur travail, c'est comme ça, les gars ! Je vous le dis, ce tableau SMM rendra votre travail fluide. Les services ici sont très bon marché, vous serez bluffés."
            }
            ,
            {
            id: 3,
            title: "Nicole Weisers",
            desc: "Si vous cherchez un moyen d'augmenter l'exposition de votre compte Instagram, alors ce tableau SMM ne vous décevra pas ! Le meilleur, c'est que tout est super bon marché, vous n'avez pas besoin de dépenser beaucoup d'argent pour obtenir les résultats souhaités."
            }

        ],
        stylefaqtitle: 'les questions les plus fréquemment posées',
        stylefaqdesc: "Découvrez les réponses aux 5 questions les plus populaires de notre Panel",
        MostAskedQuestions: [{
            id: 1,
            text1: "Pourquoi les gens recherchent-ils des panneaux SMM ?",
            text2: "Les panneaux SMM sont des boutiques en ligne proposant différents types de services SMM.",
            flag: false
            }
            ,
            {
            id: 2,
            text1: "Quels services SMM puis-je trouver sur votre tableau de bord ?",
            text2: "Notre tableau de bord propose différents types de services SMM tels que des likes, des abonnés, des vues, et bien d'autres.",
            flag: false
            }
            ,
            {
            id: 3,
            text1: "Les services SMM vendus ici sont-ils sûrs à utiliser ?",
            text2: "Oui, c'est totalement sûr, votre compte ne sera pas suspendu.",
            flag: false
            }
            ,
            {
            id: 4,
            text1: "À quoi sert la fonction de commande en gros ?",
            text2: "La fonction de commande en gros est très utile lorsque vous devez passer plusieurs commandes en même temps.",
            flag: false
            }
            ,
            {
            id: 5,
            text1: "Comment fonctionne la fonction de goutte à goutte ?",
            text2: "Vous pouvez même imiter la croissance 'organique' de votre compte sur les réseaux sociaux - la fonction de goutte à goutte vous aidera ! Supposons que vous souhaitiez commander 1000 likes pour une publication sur Instagram : vous pouvez les obtenir tous immédiatement ou de manière progressive, par exemple, 100 likes par jour pendant 10 jours.",
            flag: false
            }
        ],
        banquan: '© Copyright smm918.com. Tous droits réservés.',
        Rememberme: 'se souvenir du mot de passe',
        Forgotpassword: 'mot de passe oublié?',
        Donothaveanaccount: 'pas de compte?',
        Donothaveanaccountyou: 'vous avez déjà un compte?',
        blockfeatures__title: "Pourquoi vous devriez commander notre service SMM",
        blockfeatures__description: 'Laissez - nous vous aider à construire votre présence en ligne rapidement et efficacement.',
        playnav: [{
                id: 1,
                link: '/',
                text: 'nouvelle commande',
                spanclass: 'fa-bags-shopping'
            },
            {
                id: 2,
                link: '/orders',
                text: 'commande',
                spanclass: 'fa-ballot-check'
            },
            {
                id: 3,
                link: '/addfunds',
                text: 'ajouter des fonds',
                spanclass: 'fa-badge-dollar'
            },
            {
                id: 4,
                link: '/api',
                text: "interface d'application",
                spanclass: 'fa-ballot-check'
            }
        ],
        Category: 'catégorie',
        Service: 'service ',
        Charge: 'responsable ',
        neworder__contenttitle: 'conseils pour passer une commande',
        neworder__contenttext: 'assurez - vous de lire attentivement les instructions avant de passer votre commande',
        Submit: 'soumettre',
        ordertab: [{
                id: 1,
                text: 'tout ',
                link: '/orders'
            },
            {
                id: 2,
                text: 'accrocher',
                link: '/orders/pending'
            },
            {
                id: 3,
                text: 'en cours',
                link: '/orders/inprogress'
            },
            {
                id: 4,
                text: 'terminé',
                link: '/orders/completed'
            },
            {
                id: 5,
                text: 'partiel',
                link: '/orders/partial'
            },
            {
                id: 6,
                text: 'traitement',
                link: '/orders/processing'
            },
            {
                id: 7,
                text: 'annulé',
                link: '/orders/canceled'
            },
        ],
        data: 'date ',
        link: 'lien ',
        Startcount: 'commencer à compter',
        Quantity: 'quantité ',
        // Service: 'Service',
        Description: 'description',
        Amount: 'quantité ',
        Status: 'statut ',
        Remains: 'le reste',
        Method: 'méthode ',
        pay: 'paiement ',

    }
}