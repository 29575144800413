// module.exports = {
export default {
    userManager: {
        blocksignin__title: 'SMMサービスの王。#1 SMMパネルはオンライン',
        blocksignin__description: '私たちは超安価なフォロワー、好き、観点、その他のサービスを提供します」',
        searchBtn: '「クエリー」',
        Password: 'パスワード',
        isPassword: 'パスワードの確認」',
        Logout: '「ログアウト」',
        account: 'アカウント',
        username: '「ユーザー名」',
        email: '「電子メール」',
        departmentFullPath: '「部門のフルネーム」',
        positionName: '「部署名」',
        status: '「状態」',
        Signup: '登録',
        Signin: '「ログイン」',
        operate: '「操作」',
        api: '「アプリケーションインタフェース」',
        famedaltext1: '「最適品質」',
        famedaltext2: '「お客様に最高のSMMサービスを提供します。」',
        fareceipttext1: '「複数の支払い方法」',
        fareceipttext2: '私たちは多くの支払い方法を選択するために提供します。',
        fahandholdingusd1: '「超お得」',
        fahandholdingusd2: '「私たちはすべての製品の低価格を維持し、お客様を満足させます。」',
        fashippingfast1: '「クイックデリバリー」',
        fashippingfast2: '「当社のプラットフォームでのご注文は、超高速でお届けします。」',
        textblock__title: '「どこから？」',
        textblock__description: '私たちのパネルで注文を始めたいですか。次の4つの簡単な手順に従います。',
        contentlist: [{
                id: 1,
                title: '登録とログイン',
                desc: 'まず、登録してログインする必要があります。'
            },
            {
                id: 2,
                title: '資金の追加',
                desc: '次に、支払い方法を選択し、アカウントに資金を入金してください。'
            },
            {
                id: 3,
                title: 'サービスの選択',
                desc: 'ソーシャルメディアでより多くの露出を得るために注文を行います。'
            },
            {
                id: 4,
                title: '素晴らしい結果',
                desc: '注文が準備できたとき、結果に感銘を受けることでしょう。'
            }
        ],
        Customerexperience: '顧客エクスペリエンス',
        swiperlist: [{
                id: 1,
                title: 'リチャード・ブレイク',
                desc: '正直言って、このパネルでSMMサービスを注文した結果がこれほど素晴らしいものになるとは思っていませんでした。印象的です！間違いなくもっと注文します。'
            },
            {
                id: 2,
                title: 'ルーカス・ウッド',
                desc: 'SMMの専門家は、仕事をより簡単にする方法を常に探しています。それがこのSMMパネルです、みんな！ここで提供されるサービスは非常に安いです。驚くでしょう。'
            },
            {
                id: 3,
                title: 'ニコル・ウェザース',
                desc: 'インスタグラムアカウントの露出を増やす方法を探しているなら、このSMMパネルは間違いありません！最も良いことはすべてが非常に安いことで、お金をたくさん使う必要はありません。必要な結果を得ることができます'
            },

        ],
        stylefaqtitle: '「最もよく聞く質問」',
        stylefaqdesc: '「私たちのグループで最初の5つの最も人気のある質問の答えを見てください。」',
        MostAskedQuestions: [{
                id: 1,
                text1: "なぜ人々はSMMパネルを探しているのですか？",
                text2: "SMMパネルはさまざまな種類のSMMサービスを提供するオンラインストアです。",
                flag: false,
            },
            {
                id: 2,
                text1: "このパネルでどのようなSMMサービスを見つけることができますか？",
                text2: "当社のパネルでは、いいね、フォロワー、ビューなど、さまざまな種類のSMMサービスを提供しています。",
                flag: false,
            },
            {
                id: 3,
                text1: "ここで販売されているSMMサービスの使用は安全ですか？",
                text2: "はい、絶対に安全です。アカウントは一時停止されることはありません。",
                flag: false,
            },
            {
                id: 4,
                text1: "バルク注文機能はどのように役立ちますか？",
                text2: "複数の注文を一度に行う必要がある場合、バルク注文機能は非常に役立ちます。",
                flag: false,
            },
            {
                id: 5,
                text1: "ドリップフィードはどのように動作しますか？",
                text2: "あなたはソーシャルメディアのアカウントの「有機的」な成長を模倣することさえできます。ドリップフィードはお手伝いします！例えば、IGの投稿に1000のいいねを注文したい場合、1000のいいねをすぐに入手することも、100いいね/日などで段階的に実現することもできます。10日間続けることもできます。",
                flag: false,
            },
        ],
        banquan: '© 著作権SMM 918.COM。すべての権利を保持します。',
        Rememberme: '「パスワードを記憶して」',
        Forgotpassword: '「パスワードを忘れた？」',
        Donothaveanaccount: '「アカウントはありませんか？」',
        Donothaveanaccountyou: '「アカウントはありますか？」',
        blockfeatures__title: '「なぜSMMサービスを注文すべきですか」',
        blockfeatures__description: 'オンラインイメージを迅速かつ効果的に構築するのに役立ちます。',
        playnav: [{
                id: 1,
                link: '/',
                text: '新規オーダー',
                spanclass: 'fa-bags-shopping'
            },
            {
                id: 2,
                link: '/orders',
                text: 'オーダー',
                spanclass: 'fa-ballot-check'
            },
            {
                id: 3,
                link: '/addfunds',
                text: '「追加資金」',
                spanclass: 'fa-badge-dollar'
            },
            {
                id: 4,
                link: '/api',
                text: '「アプリケーションインタフェース」',
                spanclass: 'fa-ballot-check'
            }
        ],
        Category: 'カテゴリー',
        Service: 'サービス',
        Charge: '料金',
        neworder__contenttitle: '注文のヒント',
        neworder__contenttext: '注文をする前に、必ず説明をよく読んでください。',
        Submit: '送信',
        ordertab: [{
                id: 1,
                text: '「すべて」',
                link: '/orders'
            },
            {
                id: 2,
                text: '「保留」',
                link: '/orders/pending'
            },
            {
                id: 3,
                text: '「進行中」',
                link: '/orders/inprogress'
            },
            {
                id: 4,
                text: '「完了」',
                link: '/orders/completed'
            },
            {
                id: 5,
                text: '「部分的」',
                link: '/orders/partial'
            },
            {
                id: 6,
                text: '「加工」',
                link: '/orders/processing'
            },
            {
                id: 7,
                text: '「キャンセルされました」',
                link: '/orders/canceled'
            },
        ],
        data: '日付',
        link: 'リンク',
        Startcount: '開始カウント',
        Quantity: '数量',
        
        Description: '説明',
        Amount: '金額',
        Status: 'ステータス',
        Remains: '残り',
        Method: '方法',
        pay: '支払い',

    }
}