<template>
  <!-- <div class="login"> -->
  <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  <div class="wrapper-content">
    <div class="wrapper-content__header"></div>
    <div class="wrapper-content__body">
      <!-- Main variables *content* -->
      <div id="block_47">
        <div class="block-signin">
          <div class="bg"></div>
          <div class="divider-top"></div>
          <div class="divider-bottom"></div>
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="block-signin__content">
                  <div class="block-signin__title style-text-signin">
                    <h1>{{ $t("userManager.blocksignin__title") }}</h1>
                  </div>
                  <div class="block-signin__description style-text-signin">
                    <p>{{ $t("userManager.blocksignin__description") }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="block-signin__wrap">
                <div class="block-signin__card component_card">
                  <div class="card">
                    <!-- method="post" action="/" -->
                    <!-- <form class="component_form_group component_checkbox_remember_me"> -->
                    <div class="form-row">
                      <div class="custom-col">
                        <div class="form-group">
                          <label>{{ $t("userManager.username") }}</label>
                          <input class="form-control" type="text" v-model="formInput.username"
                            name="LoginForm[username]" />
                        </div>
                      </div>
                      <div class="custom-col">
                        <div class="form-group">
                          <label>{{ $t("userManager.Password") }}</label>
                          <input class="form-control" type="password" v-model="formInput.password"
                            name="LoginForm[password]" />
                        </div>
                      </div>
                      <div class="custom-col align-self-end">
                        <div class="form-group d-flex align-self-end component_button_submit">
                          <button class="btn btn-big-primary" type="submit" @click="signin">
                            {{ $t("userManager.Signin") }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-row align-items-center">
                      <div class="custom-col">
                        <div class="form-group">
                          <div class="form-group__checkbox">
                            <label class="form-group__checkbox-label">
                              <input type="checkbox" name="LoginForm[remember]" id="block_47_remember_me" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="form-group__label-title" for="block_47_remember_me">{{
                              $t("userManager.Rememberme") }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="custom-col">
                        <div class="form-group">
                          <div class="sign-in__forgot h-100 d-flex align-items-center">
                            <router-link to="/resetpassword">{{
                              $t("userManager.Forgotpassword")
                            }}</router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="custom-col">
                        <div class="form-group">
                          <div class="block-signin__remember">
                            <div>
                              {{ $t("userManager.Donothaveanaccount") }}
                            </div>
                            <router-link class="block-signin__link" to="/signup">{{ $t("userManager.Signup")
                            }}</router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="_csrf"
                      value="feUTbsGmmXPv6z-3rkCaP_HCOtAu1CqDwjbuV-KdSpo4qCA2ip7RC7iER5rKdfVZubp9p32SRLK7Q5Ytk8sV7w==" />
                    <!-- </form> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="block_52">
        <div class="block-features">
          <div class="bg"></div>
          <div class="divider-top"></div>
          <div class="divider-bottom"></div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="col-lg-10 pl-0 block-features__content">
                  <div class="block-features__title style-title-features">
                    <h3>
                      <strong style="font-weight: bold">{{
                        $t("userManager.blockfeatures__title")
                      }}</strong>
                    </h3>
                  </div>
                  <div class="block-features__description style-desc-features">
                    <p>{{ $t("userManager.blockfeatures__description") }}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 block-features__cards">
                <div class="row align-items-start justify-content-start">
                  <div class="col-md-6 style-features-card">
                    <div class="w-100 editor__component-wrapper">
                      <div class="card block-features__wrapper" style="">
                        <div class="features-card__preview">
                          <div
                            class="block-features__card-icon style-bg-primary-alpha-10 style-border-radius-default style-text-primary"
                            style="height: 75px; width: 75px; font-size: 75px">
                            <span class="styled-card-hover">
                              <span class="feature-block__icon style-text-primary fal fa-medal" style="
                                  width: 55px;
                                  height: 55px;
                                  transform: rotate(0deg);
                                  color: ;
                                  text-shadow: none;
                                  border-radius: 0px;
                                  background: transparent;
                                  padding: 10px;
                                "></span>
                            </span>
                          </div>
                        </div>
                        <div class="card features-card">
                          <div class="block-features__card">
                            <div class="block-features__card-content">
                              <div class="card-content__head style-text-features-title" style="
                                  margin-bottom: 10px;
                                  padding-left: 0px;
                                  padding-right: 0px;
                                ">
                                <p>
                                  <strong style="font-weight: bold">{{
                                    $t("userManager.famedaltext1")
                                  }}</strong>
                                </p>
                              </div>
                              <div class="card-content__desc style-text-features-desc"
                                style="padding-left: 0px; padding-right: 0px">
                                <p>{{ $t("userManager.famedaltext2") }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 style-features-card">
                    <div class="w-100 editor__component-wrapper">
                      <div class="card block-features__wrapper" style="">
                        <div class="features-card__preview">
                          <div
                            class="block-features__card-icon style-bg-primary-alpha-10 style-border-radius-default style-text-primary"
                            style="height: 75px; width: 75px; font-size: 75px">
                            <span class="styled-card-hover">
                              <span class="feature-block__icon style-text-primary fal fa-receipt" style="
                                  width: 55px;
                                  height: 55px;
                                  transform: rotate(0deg);
                                  color: ;
                                  text-shadow: none;
                                  border-radius: 0px;
                                  background: transparent;
                                  padding: 10px;
                                "></span>
                            </span>
                          </div>
                        </div>
                        <div class="card features-card">
                          <div class="block-features__card">
                            <div class="block-features__card-content">
                              <div class="card-content__head style-text-features-title" style="
                                  margin-bottom: 10px;
                                  padding-left: 0px;
                                  padding-right: 0px;
                                ">
                                <p>
                                  <strong style="font-weight: bold">{{
                                    $t("userManager.fareceipttext1")
                                  }}</strong>
                                </p>
                              </div>
                              <div class="card-content__desc style-text-features-desc"
                                style="padding-left: 0px; padding-right: 0px">
                                <p>{{ $t("userManager.fareceipttext2") }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 style-features-card">
                    <div class="w-100 editor__component-wrapper">
                      <div class="card block-features__wrapper" style="">
                        <div class="features-card__preview">
                          <div
                            class="block-features__card-icon style-bg-primary-alpha-10 style-border-radius-default style-text-primary"
                            style="height: 75px; width: 75px; font-size: 75px">
                            <span class="styled-card-hover">
                              <span class="feature-block__icon style-text-primary fal fa-hand-holding-usd" style="
                                  width: 55px;
                                  height: 55px;
                                  transform: rotate(0deg);
                                  color: ;
                                  text-shadow: none;
                                  border-radius: 0px;
                                  background: transparent;
                                  padding: 10px;
                                "></span>
                            </span>
                          </div>
                        </div>
                        <div class="card features-card">
                          <div class="block-features__card">
                            <div class="block-features__card-content">
                              <div class="card-content__head style-text-features-title" style="
                                  margin-bottom: 10px;
                                  padding-left: 0px;
                                  padding-right: 0px;
                                ">
                                <p>
                                  <strong style="font-weight: bold">{{
                                    $t("userManager.fahandholdingusd1")
                                  }}</strong>
                                </p>
                              </div>
                              <div class="card-content__desc style-text-features-desc"
                                style="padding-left: 0px; padding-right: 0px">
                                <p>{{ $t("userManager.fahandholdingusd2") }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 style-features-card">
                    <div class="w-100 editor__component-wrapper">
                      <div class="card block-features__wrapper" style="">
                        <div class="features-card__preview">
                          <div
                            class="block-features__card-icon style-bg-primary-alpha-10 style-border-radius-default style-text-primary"
                            style="height: 75px; width: 75px; font-size: 75px">
                            <span class="styled-card-hover">
                              <span class="feature-block__icon style-text-primary fal fa-shipping-fast" style="
                                  width: 55px;
                                  height: 55px;
                                  transform: rotate(0deg);
                                  color: ;
                                  text-shadow: none;
                                  border-radius: 0px;
                                  background: transparent;
                                  padding: 10px;
                                "></span>
                            </span>
                          </div>
                        </div>
                        <div class="card features-card">
                          <div class="block-features__card">
                            <div class="block-features__card-content">
                              <div class="card-content__head style-text-features-title" style="
                                  margin-bottom: 10px;
                                  padding-left: 0px;
                                  padding-right: 0px;
                                ">
                                <p>
                                  <strong style="font-weight: bold">{{
                                    $t("userManager.fashippingfast1")
                                  }}</strong>
                                </p>
                              </div>
                              <div class="card-content__desc style-text-features-desc"
                                style="padding-left: 0px; padding-right: 0px">
                                <p>{{ $t("userManager.fashippingfast2") }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="block_59">
        <div class="header-with-text">
          <div class="bg"></div>
          <div class="divider-top"></div>
          <div class="divider-bottom"></div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="text-block__title">
                  <h2 class="text-center">
                    <span style="text-align: CENTER">{{
                      $t("userManager.textblock__title")
                    }}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="text-block__description">
                  <p class="text-center">
                    {{ $t("userManager.textblock__description") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-for="(item,i) in $t('userManager.lists')" :key="i">{{item.text}}</div> -->
      <div id="block_58">
        <div class="how-it-works">
          <div class="bg"></div>
          <div class="divider-top"></div>
          <div class="divider-bottom"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-8">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="how-it-works__card" v-for="item in $t('userManager.contentlist')" :key="item.id">
                      <div class="card" style="">
                        <div class="how-it-works__card-wrap">
                          <div
                            class="card__number how-it-works-number style-box-shadow-default style-bg-light style-border-radius-50 style-card-number editor__component-wrapper"
                            style="
                              width: 72px;
                              height: 72px;
                              border-top-left-radius: 2px;
                              border-bottom-left-radius: 2px;
                              border-top-right-radius: 2px;
                              border-bottom-right-radius: 2px;
                              font-size: 32px;
                              color: #ffffff;
                            ">
                            {{ item.id }}
                          </div>
                          <div class="card__content">
                            <div class="content__title style-how-it-card-title" style="
                                margin-bottom: 8px;
                                padding-left: 0px;
                                padding-right: 0px;
                              ">
                              <p>
                                <strong style="font-weight: bold">{{
                                  item.title
                                }}</strong>
                              </p>
                            </div>
                            <div class="content__desc style-how-it-card-desc"
                              style="padding-left: 0px; padding-right: 0x">
                              <p>{{ item.desc }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="how-it-works__card">
                      <div class="card" style="">
                        <div class="how-it-works__card-wrap">
                          <div
                            class="card__number how-it-works-number style-box-shadow-default style-bg-light style-border-radius-50 style-card-number editor__component-wrapper"
                            style="
                              width: 72px;
                              height: 72px;
                              border-top-left-radius: 2px;
                              border-bottom-left-radius: 2px;
                              border-top-right-radius: 2px;
                              border-bottom-right-radius: 2px;
                              font-size: 32px;
                              color: #ffffff;
                            "
                          >
                            1
                          </div>
                          <div class="card__content">
                            <div
                              class="content__title style-how-it-card-title"
                              style="
                                margin-bottom: 8px;
                                padding-left: 0px;
                                padding-right: 0px;
                              "
                            >
                              <p>
                                <strong style="font-weight: bold">{{
                                  $t("userManager.content__title")
                                }}</strong>
                              </p>
                            </div>
                            <div
                              class="content__desc style-how-it-card-desc"
                              style="padding-left: 0px; padding-right: 0x"
                            >
                              <p>{{ $t("userManager.content__desc") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how-it-works__card">
                      <div class="card" style="">
                        <div class="how-it-works__card-wrap">
                          <div
                            class="card__number how-it-works-number style-box-shadow-default style-bg-light style-border-radius-50 style-card-number editor__component-wrapper"
                            style="
                              width: 72px;
                              height: 72px;
                              border-top-left-radius: 2px;
                              border-bottom-left-radius: 2px;
                              border-top-right-radius: 2px;
                              border-bottom-right-radius: 2px;
                              font-size: 32px;
                              color: #ffffff;
                            "
                          >
                            2
                          </div>
                          <div class="card__content">
                            <div
                              class="content__title style-how-it-card-title"
                              style="
                                margin-bottom: 8px;
                                padding-left: 0px;
                                padding-right: 0px;
                              "
                            >
                              <p>
                                <strong style="font-weight: bold"
                                  >Add funds</strong
                                >
                              </p>
                            </div>
                            <div
                              class="content__desc style-how-it-card-desc"
                              style="padding-left: 0px; padding-right: 0x"
                            >
                              <p>
                                Next, choose a payment method and deposit funds
                                to your account.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how-it-works__card">
                      <div class="card" style="">
                        <div class="how-it-works__card-wrap">
                          <div
                            class="card__number how-it-works-number style-box-shadow-default style-bg-light style-border-radius-50 style-card-number editor__component-wrapper"
                            style="
                              width: 72px;
                              height: 72px;
                              border-top-left-radius: 2px;
                              border-bottom-left-radius: 2px;
                              border-top-right-radius: 2px;
                              border-bottom-right-radius: 2px;
                              font-size: 32px;
                              color: #ffffff;
                            "
                          >
                            3
                          </div>
                          <div class="card__content">
                            <div
                              class="content__title style-how-it-card-title"
                              style="
                                margin-bottom: 8px;
                                padding-left: 0px;
                                padding-right: 0px;
                              "
                            >
                              <p>
                                <strong style="font-weight: bold"
                                  >Choose services</strong
                                >
                              </p>
                            </div>
                            <div
                              class="content__desc style-how-it-card-desc"
                              style="padding-left: 0px; padding-right: 0x"
                            >
                              <p>
                                Place orders and get ready to receive more
                                publicity on social media.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how-it-works__card">
                      <div class="card" style="">
                        <div class="how-it-works__card-wrap">
                          <div
                            class="card__number how-it-works-number style-box-shadow-default style-bg-light style-border-radius-50 style-card-number editor__component-wrapper"
                            style="
                              width: 72px;
                              height: 72px;
                              border-top-left-radius: 2px;
                              border-bottom-left-radius: 2px;
                              border-top-right-radius: 2px;
                              border-bottom-right-radius: 2px;
                              font-size: 32px;
                              color: #ffffff;
                            "
                          >
                            4
                          </div>
                          <div class="card__content">
                            <div
                              class="content__title style-how-it-card-title"
                              style="
                                margin-bottom: 8px;
                                padding-left: 0px;
                                padding-right: 0px;
                              "
                            >
                              <p>
                                <strong style="font-weight: bold"
                                  >Superb results</strong
                                >
                              </p>
                            </div>
                            <div
                              class="content__desc style-how-it-card-desc"
                              style="padding-left: 0px; padding-right: 0x"
                            >
                              <p>
                                You will definitely be impressed with the
                                results when your order is ready.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="block_71">
        <div class="reviews-slider">
          <div class="bg"></div>
          <div class="divider-top"></div>
          <div class="divider-bottom"></div>
          <div class="reviews-picture">
            <div class="reviews-picture__img style-review-block-image" style="
                background-image: url(https://cdn.mypanel.link/oz1d0b/yggpz81bxsahopn1.png);
              "></div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="reviews-slider">
                  <div class="reviews-slider__title style-review-card-title">
                    <h2>{{ $t('userManager.Customerexperience') }}</h2>
                  </div>
                  <div class="slider__wrap">
                    <div data-slider="1"
                      data-slider-options="{'dots':true,'arrows':false,'infinite':true,'speed':500,'autoplay':true,'autoplaySpeed':5000,'slidesToShow':1,'slidesToScroll':1,'fade':false,'rtl':false}">
                      <swiper :options="swiperOption" ref="mySwiper">
                        <swiper-slide v-for="item in $t('userManager.swiperlist')" :key="item.id">
                          <div class="reviews-slider__slide">
                            <div class="card" style="
                                background: transparent;
                                border-color: transparent;
                                padding-left: 0px;
                                padding-right: 0px;
                                box-shadow: none;
                              ">
                              <div class="reviews-slider__slide-name" style="
                                  padding-left: 0px;
                                  padding-right: 0px;
                                  margin-bottom: 24px;
                                ">
                                <p>
                                  <strong style="font-weight: bold">{{ item.title }}</strong>
                                </p>
                              </div>
                              <div class="reviews-slider__slide-description"
                                style="padding-left: 0px; padding-right: 80px">
                                <p>
                                  {{ item.desc }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                        <!-- <swiper-slide>
                          <div class="reviews-slider__slide">
                            <div
                              class="card"
                              style="
                                background: transparent;
                                border-color: transparent;
                                padding-left: 0px;
                                padding-right: 0px;
                                box-shadow: none;
                              "
                            >
                              <div
                                class="reviews-slider__slide-name"
                                style="
                                  padding-left: 0px;
                                  padding-right: 0px;
                                  margin-bottom: 24px;
                                "
                              >
                                <p>
                                  <strong style="font-weight: bold"
                                    >Richard Black</strong
                                  >
                                </p>
                              </div>
                              <div
                                class="reviews-slider__slide-description"
                                style="padding-left: 0px; padding-right: 80px"
                              >
                                <p>
                                  To be honest, I didn't even expect that the
                                  results of ordering SMM services on this panel
                                  would be so good, I'm very impressed!
                                  Definitely ordering more soon.
                                </p>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                        <swiper-slide>
                          <div class="reviews-slider__slide">
                            <div
                              class="card"
                              style="
                                background: transparent;
                                border-color: transparent;
                                padding-left: 0px;
                                padding-right: 0px;
                                box-shadow: none;
                              "
                            >
                              <div
                                class="reviews-slider__slide-name"
                                style="
                                  padding-left: 0px;
                                  padding-right: 0px;
                                  margin-bottom: 24px;
                                "
                              >
                                <p>
                                  <strong style="font-weight: bold"
                                    >Lucas Wood</strong
                                  >
                                </p>
                              </div>
                              <div
                                class="reviews-slider__slide-description"
                                style="padding-left: 0px; padding-right: 80px"
                              >
                                <p>
                                  SMM specialists constantly look for ways to
                                  make their jobs easier and THIS IS IT, GUYS!
                                  This SMM panel will make your job run
                                  smoothly, I'm telling you. Services here are
                                  so cheap that you will be blown away.
                                </p>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                        <swiper-slide>
                          <div class="reviews-slider__slide">
                            <div
                              class="card"
                              style="
                                background: transparent;
                                border-color: transparent;
                                padding-left: 0px;
                                padding-right: 0px;
                                box-shadow: none;
                              "
                            >
                              <div
                                class="reviews-slider__slide-name"
                                style="
                                  padding-left: 0px;
                                  padding-right: 0px;
                                  margin-bottom: 24px;
                                "
                              >
                                <p>
                                  <strong style="font-weight: bold"
                                    >Nicole Withers</strong
                                  >
                                </p>
                              </div>
                              <div
                                class="reviews-slider__slide-description"
                                style="padding-left: 0px; padding-right: 80px"
                              >
                                <p>
                                  If you're looking for a way to help your
                                  Instagram account more exposure, you can't go
                                  wrong with this SMM panel! And the best thing
                                  is that everything is super cheap, you don't
                                  need to spend a ton of money to get the
                                  results you want.
                                </p>
                              </div>
                            </div>
                          </div>
                        </swiper-slide> -->
                        <div class="swiper-pagination" slot="pagination"></div>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="block_65">
        <div class="faq">
          <div class="bg"></div>
          <div class="divider-top"></div>
          <div class="divider-bottom"></div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="col-lg-10 pl-0 block-faq__content">
                  <div class="block-faq__title style-faq-title">
                    <h2>
                      <strong style="font-weight: bold">{{
                        $t("userManager.stylefaqtitle")
                      }}</strong>
                    </h2>
                  </div>
                  <div class="block-faq__description style-faq-desc">
                    <p>
                      {{
                        $t("userManager.stylefaqdesc")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="row">
                  <!-- <div class="col-lg-12">
                    <div class="faq-block__card">
                      <div class="card"
                        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                        <div class="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-65-"
                          aria-expanded="false" aria-controls="#faq-block-65-">
                          <div class="faq-block__header-title">
                            <p><strong style="font-weight: bold"></strong></p>
                          </div>
                          <div class="faq-block__header-icon">
                            <div class="style-text-dark faq-block__icon" style=""></div>
                          </div>
                        </div>
                        <div class="faq-block__body collapse" id="faq-block-65-">
                          <div class="faq-block__body-description" style="padding-top: 8px">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="faq-block__card">
                      <div class="card"
                        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                        <div class="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-65-"
                          aria-expanded="false" aria-controls="#faq-block-65-">
                          <div class="faq-block__header-title">
                            <p><strong style="font-weight: bold"></strong></p>
                          </div>
                          <div class="faq-block__header-icon">
                            <div class="style-text-dark faq-block__icon" style=""></div>
                          </div>
                        </div>
                        <div class="faq-block__body collapse" id="faq-block-65-">
                          <div class="faq-block__body-description" style="padding-top: 8px">
                            <p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="faq-block__card">
                      <div class="card"
                        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                        <div class="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-65-"
                          aria-expanded="false" aria-controls="#faq-block-65-">
                          <div class="faq-block__header-title">
                            <p><strong style="font-weight: bold"></strong></p>
                          </div>
                          <div class="faq-block__header-icon">
                            <div class="style-text-dark faq-block__icon" style=""></div>
                          </div>
                        </div>
                        <div class="faq-block__body collapse" id="faq-block-65-">
                          <div class="faq-block__body-description" style="padding-top: 8px">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="faq-block__card">
                      <div class="card"
                        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                        <div class="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-65-"
                          aria-expanded="false" aria-controls="#faq-block-65-">
                          <div class="faq-block__header-title">
                            <p><strong style="font-weight: bold"></strong></p>
                          </div>
                          <div class="faq-block__header-icon">
                            <div class="style-text-dark faq-block__icon" style=""></div>
                          </div>
                        </div>
                        <div class="faq-block__body collapse" id="faq-block-65-">
                          <div class="faq-block__body-description" style="padding-top: 8px">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-lg-12" v-for="item in $t('userManager.MostAskedQuestions')" :key="item.id">
                    <div class="faq-block__card">
                      <div class="card" style="">
                        <div :class="[
                          item.flag
                            ? 'faq-block__header'
                            : 'faq-block__header collapsed',
                        ]" data-toggle="collapse" :data-target="'#faq-block-65-' + item.id"
                          :aria-expanded="item.flag" :aria-controls="'#faq-block-65-' + item.id" @click="isflag(item.id)">
                          <div class="faq-block__header-title">
                            <p>
                              <strong style="font-weight: bold">{{
                                item.text1
                              }}</strong>
                            </p>
                          </div>
                          <div class="faq-block__header-icon">
                            <div class="style-text-dark faq-block__icon" style=""></div>
                          </div>
                        </div>
                        <div class="faq-block__body collapse" :id="'faq-block-65-' + item.id">
                          <div class="faq-block__body-description" style="padding-top: 8px">
                            <p>{{ item.text2 }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-content__footer">
      <div id="block_70">
        <div class="footer">
          <div class="component_footer_single_line">
            <div class="component-footer">
              <div class="component-footer__public">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="component-footer__public-copyright">
                        <p class="text-center">
                          <span style="text-align: CENTER">{{ $t('userManager.banquan') }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import $ from "jquery";
import { mapMutations } from "vuex";
import { login } from "@/utils/http.js";

export default {
  name: "login",
  // components: {
  //   HelloWorld
  // }
  data() {
    return {
      formInput: {
        username: "",
        password: "",
      },
      MostAskedQuestions: [
        {
          id: 1,
          text1: "Why do people look for SMM panels?",
          text2:
            "SMM panels are online stores that offer SMM services of different kinds.",
          flag: false,
        },

        {
          id: 2,
          text1: "What SMM services can I find on your panel?",
          text2:
            "There are many types of SMM services available on our panel: likes, followers, views, etc.",
          flag: false,
        },
        {
          id: 3,
          text1: "Is it safe to use SMM services sold here?",
          text2: "Yes, its definitely safe, your accounts wont be suspended.",
          flag: false,
        },
        {
          id: 4,
          text1: "What is the mass order feature for?",
          text2:
            "The mass order feature helps a lot when you need to place several orders at once.",
          flag: false,
        },
        {
          id: 5,
          text1: "How does Drip-feed work?",
          text2: `You can even imitate the "organic" growth of social media accounts — and Drip-feed will
                help you with that! Let's say you want to place an order for 1000 likes on your IG post: you
                can get all 1000 right away or make it happen gradually, for example, 100 likes/day for 10
                days.`,
          flag: false,
        },
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          // type: 'fraction',
          clickable: true,
        },
      },
    };
  },
  created() {
    if (this.$cookies.get("token")) {
      // setTimeout(()=>{
      // this.$router.push("/");
        
      // },800)
    }
  },
  methods: {
    ...mapMutations([
      //采用解构的方式引入
      "getoken",
    ]),
    isflag(id) {
      let obj = this.MostAskedQuestions.find((item) => item.id == id);
      obj.flag = !obj.flag;
      // console.log($('.faq-block__body').eq(id - 1));
      $(".faq-block__body")
        .eq(id - 1)
        .slideToggle();
    },
    async signin() {
      console.log(this.formInput);
      if (this.formInput.username != '' && this.formInput.password != '') {
      let data = await login(this.formInput)
      // let data = await this.$http.post("/login", this.formInput);
      if (data.code == -1) {
        this.$message({
          message: data.message,
          type: "error",
        });
      } else if (data.code == 1) {
        console.log(data.data.token);

        this.$cookies.set("token", data.data.token, "10d");
        this.getoken(data.data.token);
        // console.log(this.$cookies.get('token'),'get');
        this.$message({
          message: data.message,
          type: "success",
        });
        console.log(data);
// setTimeout(()=>{
//   this.$router.push("/");

// },800)
        window.location.reload();
      }
    }else{
      this.$message({
          message: '用户名或密码不能为空',
          type: "error",
        });
    }

    },
  },
};
</script>
<style lang="less">
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  width: 20% !important;
}

.swiper-pagination-bullet {
  border-radius: 0 !important;
  width: 24px !important;
  height: 6px !important;
}
</style>
