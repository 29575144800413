<template>
    <div class="wrapper-content">
        <div class="wrapper-content__header">
        </div>
        <div class="wrapper-content__body">
            <!-- Main variables *content* -->
            <div id="block_62">
                <div class="new_order-block ">
                    <div class="bg"></div>
                    <div class="divider-top"></div>
                    <div class="divider-bottom"></div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-7 mb-3 mb-lg-0">
                                <div class="component_form_group component_card component_radio_button">
                                    <div class="card ">
                                        <div id="order-form">
                                            <div class="component_button_forms">
                                                <div class="alert alert-dismissible mb-3 js-order-alert  " role="alert"
                                                    style="display: none;">
                                                    <button type="button" class="close" data-hide="alert"
                                                        aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div class="text">
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="search-dropdown" id="new-order-search"
                                                        style="position: relative;" data-lang-placeholder="Search">
                                                        <div class="input-wrapper" style="position: relative;">
                                                            <button
                                                                style="position: absolute;top: 50%;transform: translateY(-50%);padding: 0px 2px;background: none;border: none;left: 12px;"
                                                                type="button">
                                                                <span class="fas fa-search"></span>
                                                            </button>
                                                            <input type="text" class="form-control" id="template-input"
                                                                v-model="searchtext" @click="activeflag=true" value="" placeholder="Search">

                                                            <!-- <ul>
                                                                <li v-for="item in searchslist" :key="item.id">{{ item.name }}</li>
                                                              </ul> -->
                                                        </div>
                                                        <div id="select-category-container" style="position: relative;" v-if="activeflag&&searchtext.length>0">
                                                            <span
                                                                class="select2-container select2-container--default select2-container--open"
                                                                style="position: absolute; top: 0px; left: 0px; width: 100%;"><span
                                                                    class="select2-dropdown dropdown-menu select2-dropdown--below"
                                                                    style="width: 100%;"><span class="select2-results">
                                                                        <div style="max-height: 100px; overflow-y: auto;">
                                                                            <ul role="group"
                                                                                class="select2-results__options dropdown-menu"
                                                                                style="padding: 0px 0px 0px;">
                                                                                <li role="listitem"  v-for="item in searchslist" :key="item.id" @click="isactive(item)"
                                                                                    class="select2-results__option"><a
                                                                                        href="#"><span
                                                                                            class="select2-selection__id badge badge-secondary badge-pill rounded-pill"><span>
                                                                                                {{item.id}}
                                                                                            </span></span> <span
                                                                                            class="select2-selection__text">
                                                                                            {{ item.name }}
                                                                                        </span> <!----></a></li>
                                                                                
                                                                        </ul>
                                                                        <div style="width: 100%; height: 0px;"></div>
                                                                    </div>
                                                                </span></span></span></div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="orderform-category" class="control-label">{{
                                                    $t("userManager.Category")
                                                    }}</label>
                                                <select class="form-control" id="orderform-category"
                                                    name="OrderForm[category]" data-select="true"
                                                    data-select-search="true" data-select-search-placeholder="Search"
                                                    data-select-container="#select-category-container"
                                                    v-model="selecteds" @change="selectedchang">
                                                    <option :value="item.id" v-for="item in $store.state.categorieslist"
                                                        :key="item.id">
                                                        {{ item.name }}
                                                    </option>

                                                </select>
                                                <div id="select-category-container" class="position-relative"></div>
                                            </div>
                                            <div class="form-group">
                                                <label for="orderform-service" class="control-label">{{
                                                    $t("userManager.Service")
                                                    }}</label>
                                                <select class="form-control" id="orderform-service"
                                                    name="OrderForm[service]" data-select="true"
                                                    data-select-search="true" data-select-search-placeholder="Search"
                                                    data-select-container="#select-service-container"
                                                    v-model="$store.state.selecteds2" @change="selectedchang2">
                                                    <option :value="item.id" v-for="item in $store.state.productslist"
                                                        :key="item.id">
                                                        {{ item.id + '-' + item.name }}
                                                    </option>
                                                </select>
                                                <div id="select-service-container" class="position-relative"></div>
                                            </div>
                                            <div class="form-group fields component_service_description"
                                                id="service_description">
                                                <label for="service_description" class="control-label">{{
                                                    $t('userManager.Description') }}</label>
                                                <div class="panel-description">
                                                    {{ $store.state.selectedDescription.describe }}
                                                </div>
                                            </div>


                                            <div class="form-group fields" id="order_quantity">
                                                <label class="control-label" for="field-orderform-fields-quantity">{{
                                                    $t('userManager.Quantity') }}</label>
                                                <input class="form-control" name="OrderForm[quantity]" value=""
                                                    type="text" id="field-orderform-fields-quantity" @change="isinput"
                                                    v-model="quantitys"><small class="help-block min-max"
                                                    >Min:
                                                    10 - Max:
                                                    50000</small>
                                            </div>
                                            <div id="fields"></div>
                                            <div class="form-group">
                                                <label for="charge" class="control-label">{{
                                                    $t("userManager.Charge")
                                                    }}</label>
                                                <input type="text" class="form-control" id="charge" v-model="isprice"
                                                    readonly>
                                            </div>
                                        </div>
                                        <input type="hidden" name="_csrf"
                                            value="8bxihe73xQw7DUQjrTzEJUWe1JxRAlgiK3I1wHPr5BTcjTPVn6KIblNgPETMTaVmDdG91gk2bRdEOXGvCp2AZQ==">
                                        <div class="new-order-button-submit component_button_submit ">
                                            <button type="submit" class="btn btn-block btn-big-primary" @click="subiit">{{
                                                $t("userManager.Submit")
                                                }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="component_content_card component_content_button">
                                <div class="card">
                                    <div class="new-order__content-title">
                                        <div class="position-relative">
                                            <p class="text-center"><span style="color: rgba(0, 137, 255, 1)"><span
                                                        style="text-align: CENTER"><span style="font-size: 28px"><strong
                                                                style="font-weight: bold">{{
                                                                $t("userManager.neworder__contenttitle")
                                                                }}</strong></span></span></span></p>
                                        </div>
                                    </div>
                                    <div class="new-order__content-text">
                                        <ul>
                                            <li>{{
                                                $t("userManager.neworder__contenttext")
                                                }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="wrapper-content__footer">
    </div>
</div></template>
<script>
import {
  mapActions
} from "vuex";
import { getCategories, getProducts } from "@/utils/http.js";
export default {
    data() {
        return {
            searchtext: '',
            quantitys: '',
            selecteds: '1',
            activeflag:false,
            produitem:{}
            // selecteds2: '1',
            // selectedDescription: {},
            // categorieslist: [],
            // productslist: []
        }
    },
    computed: {
        isprice() {
            // console.log(this.produitem);
            return ((this.quantitys * this.$store.state.produitem.price)/1000).toFixed(2)
        },
        searchslist: function () {
            var search = this.searchtext.toLowerCase()
            // if(search.length>1){
            return this.$store.state.productslist.filter(function (item) {
                return item.name.toLowerCase().indexOf(search) !== -1
            })
        // }
        }
    },
    watch: {
        searchtext(a,b){
console.log(a,b);
if(a>0){
    this.activeflag=true
}
        }  
    },
    created() {
        this.Categories2(this.$i18n.locale)
        // this.Products({"num":this.selecteds})
        this.getsites(this.$i18n.locale)
    },
    methods: {
        ...mapActions(['Categories2','Products','Productsitem','getsites']),
  subiit(){
    // console.log(this.$store.state.siteitem[0].payment_url+'/'+ Date.now()+'/'+this.isprice);
    if(this.isprice&&this.quantitys){
        window.location.href=this.$store.state.siteitem[0].payment_url+'/'+ Date.now()+'/'+this.isprice

    }else{
        // this.$message({
        //   message: '计算出总价',
        //   type: "error",
        // });
    }
  },
        isactive(obj){
            
            // this.$store.state.productslist=[]
            // this.$store.state.productslist.push(obj)
            this.$store.state.selecteds2=obj.id

            console.log(this.$store.state.productslist,'this.productslist');
            this.Productsitem({'idss': obj.id})
            // this.$forceUpdate()
            this.activeflag=false
        },
        // searchang(){
        //     console.log(this.searchtext);
        // },
        //  Categories2() {
        //     // console.log(window.sessionStorage.getItem('lang'), '0000', this.$i18n, 'pppp');

        //     // let data = await getCategories({ siteId: 1, lang: 'en' });
        //     // console.log(data);
        //     // this.categorieslist = data.data
        //     // console.log(this.categorieslist, 'categorieslist');
        //     this.Products()

        // },
        
  isinput() {
    // 将输入框的值转换为数字
    this.quantitys = this.quantitys.replace(/[^\d]/g, '');
    let number = parseInt(this.quantitys);
    
    // 判断输入的数字是否在10-50000范围内
    if (isNaN(number) || number < 10 || number > 50000) {
      // 当输入不符合要求时，重置输入框的值
      this.quantitys = '';
      this.$message({
          message: '数量限制为10-50000',
          type: "error",
        });
    }
  
},
        selectedchang(e) {
            console.log(this.selecteds, e, 'eeeee');

            // let obj = this.$store.state.categorieslist.find(item => item.id == this.selecteds)
            // console.log(obj, 'objojb');
            // this.$store.state.selectedDescription = obj
            this.Products({"num":this.selecteds,"lang":this.$i18n.locale})
        },
        // async Products() {
        //     let data2 = await getProducts({ siteId: this.selecteds, lang: 'en' });
        //     console.log(data2);
        //     this.productslist = data2.data
        //     console.log(this.productslist, ' this.productslist this.productslist');
        //     this.selecteds2 = this.productslist[0].id
        //     this.selectedDescription = this.productslist[0]
        // },
        selectedchang2(e) {
            console.log(e,this.$store.state.selecteds2);
this.Productsitem({'idss': this.$store.state.selecteds2})
            // let obj = this.$store.state.productslist.find(item => item.id == this.$store.state.selecteds2)
            // console.log(obj, 'objojb');
            // this.produitem = obj
            // this.selectedDescription=this.productslist[this.selecteds2-1].description
            // console.log(this.selecteds2,  '173');
            // console.log(this.this.productslist[this.selecteds2-1],  '174');

            // this.Products()
        },
    }
}
</script>