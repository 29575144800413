import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookies from 'vue-cookies'
import axios from 'axios'
import ElementUI, { Loading } from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from './lang'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.css'
import '@/assets/css/oz1d0b.css'
import 'swiper/dist/css/swiper.css'



Vue.use(ElementUI).use(cookies).use(VueAwesomeSwiper)
 axios.defaults.baseURL = 'https://datapi.site';

// Vue.use(ElementUI)
// if(process.env.VUE_APP_ENV === 'dev') {
//  axios.defaults.baseURL = '/api';
// } else {
  // axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// }
let logs = null
// 请求拦截
axios.interceptors.request.use(config => {
  logs = Loading.service({
    fullscreen: true
  })
  return config
})
// 响应拦截
axios.interceptors.response.use(response => {
  logs.close()
  return response.data
})

Vue.prototype.$http = axios

// Vue.prototype.$cookies = cookies; 
//配置cookies生命周期，单位不区分大小写
// this.$cookies.config('1d') //填的值1d为一天,1h为一小时,1min为一分钟,1s为1秒：如


Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
