import Vue from 'vue'
import Vuex from 'vuex'
import { getCategories, getProducts,getSite } from "@/utils/http.js";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    categorieslist: [],
    productslist: [],
    selecteds11:0,
    selecteds2:1,
    selectedDescription:{},
    produitem:{},
    siteitem:[]
  },
  getters: {},
  mutations: {
    getoken(state, ell) {
      state.token = ell
      console.log(state.token, 'vuexxxx');
    }
  },
  actions: {
    async Categories2(cumm,oo) {
      console.log(cumm,oo,'cummcumm',window.sessionStorage.getItem('lang'), '0000', this.$i18n, 'pppp');

      let data = await getCategories({ siteId: 1, lang: oo });
      cumm.state.categorieslist=data.data
    // cumm.state.selectedDescription = cumm.state.categorieslist

      // console.log(data);
      // this.categorieslist = data.data
      // console.log(this.categorieslist, 'categorieslist');
      // this.Products()
      

  },
  async getsites(cumm,oo) {
  

    let data = await getSite({ lang: oo });
    cumm.state.siteitem=data.data
  

},
  async Products(cumm,oo) {
    console.log(cumm,oo,'cummcumm',window.sessionStorage.getItem('lang'), '0000', this.$i18n, 'pppp');

    let data2 = await getProducts({ siteId: oo.num, lang: oo.lang });
    cumm.state.productslist=data2.data
    // console.log(cumm.state.productslist[0]);
    console.log(oo.num);
    if(oo.num){
      cumm.state.selecteds2 = cumm.state.productslist[0].id
      // cumm.state.selectedDescription = cumm.state.productslist[0]

      let obj0 = cumm.state.productslist.find(item => item.id == cumm.state.selecteds2)
      // console.log(obj, 'objojb');
      cumm.state.produitem = obj0
      
      cumm.state.selecteds11 = oo.num
       let obj = cumm.state.categorieslist.find(item => item.id == cumm.state.selecteds11)
              console.log(obj, 'objojb');
              cumm.state.selectedDescription = obj
    }
   
    
},

Productsitem(cumm,oo) {
    let obj0 = cumm.state.productslist.find(item => item.id == oo.idss)
    // console.log(obj, 'objojb');
    cumm.state.produitem = obj0
    
   
 
  
},
  },
  modules: {}
})